import { useCollectionLayout } from "@bottlebooks/bottlebooks-site-base/src/components/CollectionLayoutProvider.next";
import useImageUpload from "@bottlebooks/bottlebooks-site-base/src/useImageUpload";
import type {
  MutationResponse,
  ResponseCallbacks,
} from "@bottlebooks/bottlebooks-site-base/src/useMutationState";
import useMutationState from "@bottlebooks/bottlebooks-site-base/src/useMutationState";
import { graphql } from "~/gql";
import { ContentLocale } from "~/gql/graphql";

export default function useProfileSubmission({
  uid,
  locale,
}: {
  uid: string;
  locale: ContentLocale;
}) {
  const { collectionId } = useCollectionLayout();
  const { uploadImage } = useImageUpload();
  console.log("ONBOARD_REPRESENTATIVE", ONBOARD_REPRESENTATIVE);
  const [submit, result] = useMutationState<MutationResponse<Data>, Variables>(
    ONBOARD_REPRESENTATIVE
  );
  async function updateProfile(
    input: Omit<Variables["input"], "uid" | "hasAcceptedTerms">,
    options?: ResponseCallbacks<MutationResponse<Data>>
  ) {
    if (!locale) throw new Error("Locale is required");
    console.log("before submitting:", input.profileImage);
    try {
      const newProfileImageUrl = await uploadImage(input.profileImage);
      return submit(
        {
          locale,
          collectionId,
          input: {
            uid,
            displayName: input.displayName || null,
            jobTitle: input.jobTitle || null,
            companyName: input.companyName || null,
            telephone: input.telephone || null,
            linkedInURL: input.linkedInURL || null,
            // GraphQL cannot support image uploads
            // We must upload to REST API first and then pass the URL to GraphQL
            // profileImage: input.profileImage,
            profileImageUrl:
              newProfileImageUrl || input.profileImageUrl || null,
            // When submitting, the user agrees to the terms, so we set it here.
            hasAcceptedTerms: true,
          },
        },
        options
      );
    } catch (error) {
      console.log(error);
    }
  }
  return [updateProfile, result] as const;
}

const ONBOARD_REPRESENTATIVE = graphql(`
  mutation updateAttendeeProfile(
    $collectionId: ID!
    # $locale: String!
    # $token: String!
    $input: SiteUserProfileInput!
  ) {
    response: updateAttendeeProfile(
      # locale: $locale
      collectionId: $collectionId
      # updateToken: $token
      input: $input
    ) {
      code
      success
      message
      profile {
        uid
        displayName
        jobTitle
        companyName
        profileImageUrl
      }
    }
  }
`);

type Variables = {
  collectionId: string;
  locale: string;
  // token: string;
  input: {
    uid: string;
    companyName?: string | null | undefined;
    displayName?: string | null | undefined;
    email?: string | null | undefined;
    jobTitle?: string | null | undefined;
    linkedInURL?: string | null | undefined;
    telephone?: string | null | undefined;
    profileImage?: File | null | undefined;
    hasAcceptedTerms: boolean;
    profileImageUrl?: string | null | undefined;
  };
};

type Data = {
  profile: {
    uid: string;
    displayName: string;
    jobTitle: string | null | undefined;
    companyName: string | null | undefined;
    profileImageUrl: string | null | undefined;
  };
};
