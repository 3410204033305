/** @jsxImportSource theme-ui */
import { Text } from "@bottlebooks/gatsby-theme-base/src";
import ProductPrice from "./ProductPrice/ProductPrice.next";
import { FragmentType, graphql, useFragment } from "~/gql";

const fragment = graphql(/* GraphQL */ `
  fragment ProductHeaderPrice on RegisteredProduct {
    ...ProductPrice
  }
`);

export default function ProductHeaderPrice({
  pricing: data,
  showMorePricingInfo,
  ...rest
}: {
  pricing: FragmentType<typeof fragment>;
  showMorePricingInfo?: boolean;
}) {
  const registeredProduct = useFragment(fragment, data);
  // if (!ProductPrice.hasContent(registeredProduct)) return null;
  return (
    <Text variant="smallest" sx={{ paddingLeft: 1, marginLeft: 3 }} {...rest}>
      <ProductPrice
        pricing={registeredProduct}
        showMorePricingInfo={showMorePricingInfo}
        prependPriceType={true}
      />
    </Text>
  );
}
