import CollectionLayoutProvider from "@bottlebooks/bottlebooks-site-base/src/components/CollectionLayoutProvider.next";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { lighten } from "polished";
import { LoaderFunctionArgs, Outlet } from "react-router-dom";
import { ThemeUIProvider } from "theme-ui";
import { z } from "zod";
import FirebaseProviders from "~/components/FirebaseProviders";
import { graphql } from "~/gql";
import graphQLClient from "~/graphQLClient";
import queryClient from "~/queryClient";
import { useParams } from "~/router";
import { theme as baseTheme } from "@bottlebooks/gatsby-design-system";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
  collectionId: z.string().length(24),
  taskId: z.string().length(24),
});

function query(params: {
  locale: z.infer<typeof paramsSchema>["locale"];
  collectionId: string;
  taskId: string;
}) {
  return queryOptions({
    queryKey: [
      "collection",
      params.locale,
      params.collectionId,
      "tasks",
      params.taskId,
    ],
    throwOnError: true,
    queryFn: async () => {
      const result = await graphQLClient.request(
        graphql(/* GraphQL */ `
          query ProductPagesLayout($collectionId: ID!, $locale: ContentLocale) {
            collection(collectionId: $collectionId, locale: $locale) {
              collectionId
              site {
                templateSettings {
                  name
                  value
                }
                primaryColor
                accentColor
              }
              ...CollectionLayoutProvider
            }
          }
        `),
        {
          locale: params.locale,
          collectionId: params.collectionId,
        }
      );
      const collection = result.collection;
      if (!collection)
        throw new Error(
          `We couldn't find the collection ${params.collectionId}.`
        );
      return { collection };
    },
  });
}

export async function Loader(args: LoaderFunctionArgs) {
  await queryClient.prefetchQuery(query(paramsSchema.parse(args.params)));
  return null;
}

export default function TaskLayout() {
  const params = paramsSchema.parse(
    useParams("/:locale/c/:collectionId/tasks/:taskId")
  );
  const { data } = useQuery(query(params), queryClient);
  const collection = data?.collection;
  if (!collection) return null;
  return (
    <CollectionLayoutProvider
      collectionId={collection.collectionId}
      data={{
        ...collection,
        // @ts-expect-error We are passing in some default values
        site: {
          ...collection.site,
          templateSettings: [
            { name: "isChatEnabled", value: "false" },
            { name: "bookmarksEnabled", value: "false" },
            { name: "tastingNotesEnabled", value: "false" },
            { name: "listsEnabled", value: "false" },
            ...(collection.site?.templateSettings || []),
          ],
        },
      }}
    >
      <FirebaseProviders>
        <ThemeUIProvider
          theme={{
            colors: {
              primary:
                collection.site?.primaryColor ?? baseTheme.colors.primary,
              accent: collection.site?.accentColor ?? baseTheme.colors.accent,
              secondary: lighten(0.1)(
                collection.site?.primaryColor ?? baseTheme.colors.primary
              ),
            },
          }}
        >
          <Outlet />
        </ThemeUIProvider>
      </FirebaseProviders>
    </CollectionLayoutProvider>
  );
}
