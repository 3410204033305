/** @jsxImportSource theme-ui */
import { Box, Col, Container, Title } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import useLink from "../../useLink";
import BrandAddressSection from "../Brand/BrandAddressSection.next";
import BrandBanner from "../Brand/BrandBanner.next";
import BrandShopsSection from "../Brand/BrandShopsSection.next";
import MarketingActivitiesSection from "../Brand/MarketingActivitiesSection.next";
import ContentRow from "../ContentRow";
import ExhibitorActions from "../Exhibitor/ExhibitorActions.next";
import ProductCard from "../ProductCard/ProductCard.next";
import ProductListListRow from "../ProductListsList/ProductListListRow";
import ProductListsList from "../ProductListsList/ProductListsList";
import ProductsList from "../ProductsList/ProductsList";
import ExhibitorHeaderRow from "./ExhibitorHeaderRow.next";
import ExhibitorSections from "./ExhibitorSections.next";
// import SEO from "../seo.js";

const fragment = graphql(/* GraphQL */ `
  fragment ExhibitorPage on SingleRegistration {
    registrationId
    companyId
    ...ExhibitorHeaderRow
    profile {
      ...BrandBanner
      ...BrandAddressSection_Profile
      ...BrandShopsSection
      # Not exposed on GraphQL
      # ...MarketingActivitiesSection
    }

    ...ExhibitorActions_SingleRegistration
    ...ExhibitorStand_SingleRegistration
    ...ExhibitorSections
    forceHideBrands: customFieldValue(
      key: "forceHideBrands"
      _unstable_hidden: true
      experimentalKey: "jonathan@bottlebooks.me: @bottlebooks-site/packages/bottlebooks-site-base/src/components/ExhibitorsPage/exhibitorFilters.next.ts (wfsUk)"
    ) {
      __typename
      ... on YesNoFieldValue {
        value
      }
    }
    customShortDescription: customFieldValue(
      key: "__inject__shortDescription"
    ) {
      ... on TextFieldValue {
        value
      }
    }
    exhibitorType: customFieldValue(
      key: "exhibitorType"
      _unstable_hidden: true
      experimentalKey: "jonathan@bottlebooks.me: @bottlebooks-site/packages/bottlebooks-site-base/src/components/ExhibitorsPage/exhibitorFilters.next.ts (wfsUk)"
    ) {
      __typename
      ... on SelectFieldValue {
        key
      }
    }
    registeredProducts {
      nodes {
        ...ProductCard_RegisteredProduct
        productId
        companyId
        ...ProductListRow
        product {
          productId
          slug
          ...ProductCard
          ...useLink_Product
        }
      }
    }
  }
`);

export default function ExhibitorPage({
  eventId,
  data,
  productLists,
  previous,
  next,
}: {
  eventId: string;
  data: FragmentType<typeof fragment>;
  productLists?: { id: string; productListId: string }[] | null | undefined;
  previous: any;
  next: any;
}) {
  const singleRegistration = useFragment(fragment, data);
  const link = useLink();
  const registeredProducts = singleRegistration?.registeredProducts?.nodes;
  const exhibitor = { ...singleRegistration, ...singleRegistration?.profile };
  const hasProducts = registeredProducts && registeredProducts.length !== 0;
  const hasProductLists = productLists && productLists.length !== 0;
  const hasProductsSection = hasProducts || hasProductLists;
  const forceHideBrands =
    singleRegistration.forceHideBrands?.__typename === "YesNoFieldValue"
      ? singleRegistration.forceHideBrands?.value
      : false;
  return (
    <Box>
      {/* <SEO page={page} /> */}
      <BrandBanner data={exhibitor} />
      <ExhibitorHeaderRow data={singleRegistration} />

      <Container>
        <ContentRow sx={{ paddingY: 3 }}>
          <Col>
            <ExhibitorActions data={singleRegistration} />
          </Col>
        </ContentRow>
      </Container>

      <MarketingActivitiesSection brand={exhibitor} />

      <ExhibitorSections data={singleRegistration} eventId={eventId} />

      <BrandAddressSection data={singleRegistration.profile} />

      <Container>
        <ContentRow>
          <Col>
            <BrandShopsSection data={exhibitor} />
          </Col>
        </ContentRow>
      </Container>

      {hasProductsSection ? (
        <Container fluid sx={{ maxWidth: "container.fluid" }}>
          <Title sx={{ paddingY: 4 }}>
            <Trans>Products</Trans>
          </Title>
          {hasProductLists && (
            <ProductListsList sx={{ marginX: [-3, -2], marginY: -2 }}>
              {productLists.map((productList) => (
                <ProductListListRow
                  key={productList.id}
                  productList={productList}
                  to={link.productList(productList)}
                  sx={{ marginX: [0, 2], marginY: [0, 2] }}
                />
              ))}
            </ProductListsList>
          )}
          {hasProducts && (
            <ProductsList
              count={registeredProducts.length}
              sx={{ marginX: [-3, -2], marginY: -2 }}
            >
              {registeredProducts.map((registeredProduct) => (
                <ProductCard
                  key={registeredProduct.productId}
                  forceHideBrands={forceHideBrands ?? false}
                  product={registeredProduct}
                  sx={{ marginBottom: [null, null, 3] }}
                />
              ))}
            </ProductsList>
          )}
        </Container>
      ) : null}
    </Box>
  );
}
