import fetchGraphQL from "~/fetchGraphQL";
import { graphql } from "~/gql";
import { SitemapQuery } from "~/gql/graphql";
import { create } from "xmlbuilder2";
import { useLoaderData } from "react-router-dom";
import fetchSanity from "~/fetchSanity";
import { gql } from "urql";
// const fetch = require("node-fetch"); // If you need to get dynamic URLs
// const { format, create } = require("xmlbuilder2");

type LoaderData = Awaited<ReturnType<typeof Loader>>;
export async function Loader() {
  const domain = "winesfromspainawards.com";
  // Don't return sitemap for other domains
  if (domain === "events.bottlebooks.me") return "";
  const result = await fetchGraphQL<SitemapQuery>(
    graphql(`
      query Sitemap($customDomain: String!) {
        collection: _unstable_collectionByCustomDomain(
          experimentalKey: "jonathan@bottlebooks.me: @bottlebooks/bottlebooks-site/src/pages/[locale]/_layout.tsx"
          customDomain: $customDomain
        ) {
          collectionId
          locales
          site {
            templateSettings {
              name
              value
            }
          }
          registrations {
            nodes {
              __typename
              ... on SingleRegistration {
                companyId
                registeredProducts {
                  nodes {
                    productId
                  }
                }
                registeredBrands {
                  nodes {
                    brandId
                  }
                }
              }
            }
          }
        }
      }
    `),
    { customDomain: domain }
  );
  const collection = result.data?.collection;
  if (!collection)
    throw new Error(`No collection found for this domain: ${domain}`);
  const registrations = collection.registrations.nodes;
  const singleRegistrations = registrations.flatMap((registration) => {
    if (registration.__typename === "SingleRegistration") {
      return registration;
    }
    return [];
  });
  const dynamicUrls = singleRegistrations.flatMap((registration) => {
    const registrationUrl = {
      url: `/en/exhibitors/${registration.companyId}`,
      changefreq: "daily",
      priority: 0.8,
    };
    const registeredProductsUrls = registration.registeredProducts.nodes.map(
      (registeredProduct) => {
        return {
          url: `/en/products/by-exhibitor/${registration.companyId}/${registeredProduct.productId}`,
          changefreq: "daily",
          priority: 0.8,
        };
      }
    );
    const registeredBrandsUrls = registration.registeredBrands.nodes.map(
      (registeredBrand) => {
        return {
          url: `/en/producers/${registeredBrand.brandId}`,
          changefreq: "daily",
          priority: 0.8,
        };
      }
    );
    return [
      registrationUrl,
      ...registeredProductsUrls,
      ...registeredBrandsUrls,
    ];
  });

  const templateSettings = collection.site?.templateSettings ?? [];
  const projectId = templateSettings.find(
    ({ name }) => name === "projectId"
  )?.value;
  const sanityResult = projectId
    ? await fetchSanity(
        gql`
          query ($collectionId: String!) {
            allSite(where: { collectionId: { eq: $collectionId } }) {
              collectionId
              frontpage {
                __typename
                id: _id
                title
                slug {
                  current
                }
              }
              mainNavigation {
                name
                page {
                  __typename
                  ... on BottlebooksPage {
                    type
                    id: _id
                  }
                  ... on Page {
                    id: _id
                    slug {
                      current
                    }
                  }
                  ... on ExternalPage {
                    title
                    type
                    url
                  }
                }
              }
            }
          }
        `,
        { collectionId: collection.collectionId },
        {
          projectId,
        }
      )
    : null;
  const site = sanityResult?.data?.allSite?.[0];
  const sanityUrls = site.mainNavigation
    ?.map((menuItem) => {
      switch (menuItem.page.__typename) {
        case "BottlebooksPage":
          // Landing page
          // There is a standard page type of landing page that users
          // can select. It is necessary because the landing page should
          // not have a slug.
          if (menuItem.page.type === "")
            return {
              url: `/en`,
              changefreq: "daily",
              priority: 1.0,
            };
          else
            return {
              url: `/en/${menuItem.page.type}`,
              changefreq: "daily",
              priority: 0.8,
            };
        case "Page":
          // Regular page
          return {
            url: `/${menuItem.page.slug.current}`,
            changefreq: "daily",
            priority: 0.8,
          };
        case "ExternalPage":
        default:
          return null;
      }
    })
    .filter(Boolean);

  const urls = [
    ...new Set([
      ...sanityUrls,
      { url: "/en", changefreq: "daily", priority: 1.0 },
      { url: "/en/exhibitors", changefreq: "daily", priority: 0.8 },
      { url: "/en/producers", changefreq: "daily", priority: 0.8 },
      { url: "/en/products", changefreq: "daily", priority: 0.8 },
      ...dynamicUrls,
    ]),
  ];

  const root = create({ version: "1.0", encoding: "UTF-8" }).ele("urlset", {
    xmlns: "http://www.sitemaps.org/schemas/sitemap/0.9",
  });

  // Append URLs to the sitemap
  urls.forEach(({ url, changefreq, priority }) => {
    root
      .ele("url")
      .ele("loc")
      .txt(`https://${domain}${url}`)
      .up()
      .ele("changefreq")
      .txt(changefreq)
      .up()
      .ele("priority")
      .txt(String(priority))
      .up();
  });

  // Convert to XML string
  const sitemapXml = root.end({ prettyPrint: true });
  return {
    statusCode: 200,
    headers: {
      "Content-Type": "application/xml",
    },
    body: sitemapXml,
  };
}

export default function SitemapPage() {
  const data = useLoaderData() as LoaderData;
  // set dangerouslySetInnerHTML to avoid XSS attacks
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: data.body,
      }}
    />
  );
}
