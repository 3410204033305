/** @jsxImportSource theme-ui */
import { getApp } from "firebase/app";
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import React from "react";
import { useParams, useNavigate } from "~/router";

export default function ValidateSigninLinkTemplate() {
  const params = useParams(
    "/:locale/collections/:collectionId/validateSigninLink"
  );
  const navigate = useNavigate();
  React.useEffect(() => {
    // Confirm the link is a sign-in with email link.
    const auth = getAuth(getApp());
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
      }
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          // Clear email from storage.
          window.localStorage.removeItem("emailForSignIn");
          navigate("/:locale/collections/:collectionId/my/profile", { params });
        })
        .catch((error) => {
          navigate("/:locale/collections/:collectionId/my/profile", { params });
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  }, []);

  return null;
}
