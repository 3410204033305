/** @jsxImportSource theme-ui */
import { Flex } from "@bottlebooks/gatsby-design-system";
import getOnAccentColor from "@bottlebooks/bottlebooks-site-base/src/helpers/getOnAccentColor";
// import { graphql } from "gatsby";
import { CallToAction, RichText } from "..";

/**
 *
 * @param {object} props
 * @param {JSX.Element} props._rawText
 * @param {JSX.Element} props.callToAction
 * @param {string} props.sectionVariant
 * @param {'left' | 'center' | 'right'} props.alignment
 * @returns
 */
export default function TextBlock({
  _rawText,
  callToAction,
  sectionVariant,
  alignment = "left",
  ...rest
}) {
  return (
    <Flex
      direction="column"
      sx={(theme) => {
        return {
          alignItems:
            alignment === "center"
              ? "center"
              : alignment === "right"
              ? "flex-end"
              : "flex-start",
          color:
            sectionVariant === "accent"
              ? getOnAccentColor(theme.colors.accent)
              : "inherit",
        };
      }}
      {...rest}
    >
      <span
        style={{
          textAlign: alignment,
        }}
      >
        <RichText>{_rawText}</RichText>
      </span>
      <CallToAction {...callToAction} sectionVariant={sectionVariant} />
    </Flex>
  );
}

// export const fragment = graphql`
//   fragment SanityTextBlockFragment on SanityTextBlock {
//     _rawText
//     callToAction {
//       ...CallToAction
//     }
//     alignment
//   }
// `;
