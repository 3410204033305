import {
  layouts,
  useCollectionLayout,
} from "@bottlebooks/bottlebooks-site-base/src/components/CollectionLayoutProvider.next";
import { Box, Button, Card, Flex, Grid, Stack, Text } from "@mantine/core";
import { Link, useParams } from "~/router";

export default function SiteAdmin() {
  const params = useParams("/:locale");
  const collectionLayout = useCollectionLayout();
  return (
    <Flex bg="grey.0" justify="center" py="md">
      <Grid w="800">
        <Grid.Col span={{ base: 12, sm: 6 }}>
          <Card shadow="xs">
            <Card.Section inheritPadding py="xs">
              <Text fw={600}>Settings</Text>
            </Card.Section>
            <Card.Section inheritPadding py="xs">
              <Stack>
                <Box>
                  <Text fw={600}>Chat (isChatEnabled)</Text>
                  <Text>
                    {collectionLayout.siteConfig.isChatEnabled
                      ? "true"
                      : "false"}
                  </Text>
                </Box>
                <Box>
                  <Text fw={600}>
                    Meeting booking (isMeetingBookingEnabled)
                  </Text>
                  <Text>
                    {collectionLayout.siteConfig.isMeetingBookingEnabled
                      ? "true"
                      : "false"}
                  </Text>
                </Box>
                <Box>
                  <Text fw={600}>Lists (listsEnabled)</Text>
                  <Text>
                    {collectionLayout.siteConfig.listsEnabled
                      ? "true"
                      : "false"}
                  </Text>
                </Box>
                <Box>
                  <Text fw={600}>Tasting notes (tastingNotesEnabled)</Text>
                  <Text>
                    {collectionLayout.siteConfig.tastingNotesEnabled
                      ? "true"
                      : "false"}
                  </Text>
                </Box>

                <Button
                  onClick={() => {
                    window.open(
                      `https://app.bottlebooks.me/selectBrand/${collectionLayout.workspaceId}?redirect_url=/dashboard/app/next/en/${collectionLayout.workspaceId}/c/${collectionLayout.collectionId}/en/settings/outputs`,
                      "_blank"
                    );
                  }}
                >
                  Configure
                </Button>
              </Stack>
            </Card.Section>
          </Card>
        </Grid.Col>
        <Grid.Col span={{ base: 12, sm: 6 }}>
          <Card shadow="xs">
            <Card.Section inheritPadding py="xs">
              <Text fw={600}>Layout</Text>
            </Card.Section>
            <Card.Section inheritPadding py="xs">
              <Stack>
                <Text>
                  {collectionLayout.siteConfig.layout}{" "}
                  {collectionLayout.siteConfig.layout === "BOTTLEBOOKS"
                    ? "(default)"
                    : "(custom)"}
                </Text>
                <Box>
                  <Text fw={600}>Valid layouts</Text>
                  <Text>{layouts.join(", ")}</Text>
                </Box>
                <Box>
                  <Text fw={600}>Sanity</Text>
                  <Text>{collectionLayout.isSanity ? "true" : "false"}</Text>
                </Box>
                <Box>
                  <Text fw={600}>Sanity projectId</Text>
                  <Text>{collectionLayout.projectId ?? "---"}</Text>
                </Box>
                <Button
                  onClick={() => {
                    window.open(
                      `https://app.bottlebooks.me/selectBrand/${collectionLayout.workspaceId}?redirect_url=/dashboard/app/next/en/${collectionLayout.workspaceId}/c/${collectionLayout.collectionId}/en/settings/outputs`,
                      "_blank"
                    );
                  }}
                >
                  Configure
                </Button>
              </Stack>
            </Card.Section>
          </Card>
        </Grid.Col>
        <Grid.Col span={{ base: 12, sm: 6 }}>
          <Card shadow="xs">
            <Card.Section inheritPadding py="xs">
              <Text fw={600}>Exhibitor filters</Text>
            </Card.Section>
            <Card.Section inheritPadding py="xs">
              <Stack>
                <Box>
                  <Text fw={600}>Filters</Text>
                  <Text>
                    {collectionLayout.siteConfig.exhibitorFilters?.length
                      ? collectionLayout.siteConfig.exhibitorFilters?.join(", ")
                      : "Default filters"}
                  </Text>
                </Box>
                <Box>
                  <Text fw={600}>Distribution details</Text>
                  <Text>
                    {collectionLayout.siteConfig.showDistributionDetails}
                  </Text>
                </Box>
                <Button
                  component={Link}
                  to="/:locale/exhibitors/filters"
                  params={params}
                >
                  Edit
                </Button>
              </Stack>
            </Card.Section>
          </Card>
        </Grid.Col>
        <Grid.Col span={{ base: 12, sm: 6 }}>
          <Card shadow="xs">
            <Card.Section inheritPadding py="xs">
              <Text fw={600}>Product filters</Text>
            </Card.Section>
            <Card.Section inheritPadding py="xs">
              <Stack>
                <Box>
                  <Text fw={600}>Filters</Text>
                  <Text>
                    {collectionLayout.siteConfig.productFilters?.length
                      ? collectionLayout.siteConfig.productFilters?.join(", ")
                      : "Default filters"}
                  </Text>
                </Box>
                <Button
                  component={Link}
                  to="/:locale/products/filters"
                  params={params}
                >
                  Edit
                </Button>
              </Stack>
            </Card.Section>
          </Card>
        </Grid.Col>
      </Grid>
    </Flex>
  );
}
