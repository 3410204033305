/** @jsxImportSource theme-ui */
import { Flex } from "@bottlebooks/gatsby-design-system";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Field, Form, Formik } from "formik";
import { ArrowLeft } from "@phosphor-icons/react";
import type { ComponentPropsWithoutRef, ReactNode } from "react";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { TextField } from "../Form/Field";
import IconButton from "../IconButton";
import type {
  SignInWithEmailContext,
  SignInWithEmailOptions,
} from "./Login.useState";

type EnterEmailProps = ComponentPropsWithoutRef<typeof Flex> &
  SignInWithEmailOptions &
  SignInWithEmailContext & {
    prepend?: ReactNode;
    append?: ReactNode;
  };

export const emailInitialSchema = z
  .object({
    email: z.string().trim().default(""),
    password: z.string().default(""),
  })
  .passthrough();

export const emailSchema = z.object({
  ...emailInitialSchema.shape,
  email: z.string().trim().email(),
  collectionId: z.string().length(24),
});

export type EmailSchema = z.infer<typeof emailSchema>;

export const requestSigninLinkSchema = emailSchema.omit({
  password: true,
});

export type RequestLinkSchema = z.infer<typeof requestSigninLinkSchema>;

export default function EnterEmail({
  onContinue,
  onBack,
  prepend,
  append,
  initialValues,
  ...rest
}: EnterEmailProps) {
  const { i18n } = useLingui();
  console.log("initialValues", initialValues);
  return (
    <Formik<EmailSchema>
      validationSchema={toFormikValidationSchema(emailSchema)}
      initialValues={initialValues}
      onSubmit={(values) => onContinue(values)}
    >
      <Form>
        <Flex direction="column" gap={3} sx={{ textAlign: "left" }} {...rest}>
          {prepend}
          <TextField
            name="email"
            type="email"
            label={i18n._(t`Your email`)}
            autoFocus
          />
          <Field type="password" name="password" sx={{ display: "none" }} />
          <Flex gap={3} justify="space-between">
            <IconButton
              variant="base"
              sx={{ paddingLeft: 0, color: "lightText" }}
              onClick={onBack}
            >
              <ArrowLeft size={22} weight="light" />
              <Trans>Back</Trans>
            </IconButton>
            <IconButton type="submit" variant="primary">
              <Trans>Next</Trans>
            </IconButton>
          </Flex>
          {append}
        </Flex>
      </Form>
    </Formik>
  );
}
