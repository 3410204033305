/** @jsxImportSource theme-ui */
import { LoaderFunctionArgs } from "react-router-dom";
import { z } from "zod";
import { redirect } from "~/router";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
  exhibitorId: z.string(),
});

export function Loader(args: LoaderFunctionArgs) {
  const params = paramsSchema.parse(args.params);
  return redirect("/:locale/exhibitors/:exhibitorId", { params });
}
