import { useCollectionLayout } from "@bottlebooks/bottlebooks-site-base/src/components/CollectionLayoutProvider.next";
import BookmarksPage from "@bottlebooks/bottlebooks-site-bookmarks/src/components/BookmarksPage";
import { z } from "zod";
import { useParams } from "~/router";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
});

export default function BookmarksRoute({ ...rest }) {
  const params = useParams("/:locale/my/bookmarks");
  const { locale } = paramsSchema.parse(params);
  const { collectionId } = useCollectionLayout();
  return (
    <BookmarksPage locale={locale} collectionId={collectionId} {...rest} />
  );
}
