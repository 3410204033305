import { LoaderFunctionArgs } from "react-router-dom";
import env from "~/env";

export default function getCustomDomain(args: LoaderFunctionArgs) {
  if (env.VITE_CUSTOM_DOMAIN) return env.VITE_CUSTOM_DOMAIN;
  const match = args.request.url.match(/^(?:https?:\/\/)?(?:www\.)?([^\/:]+)/);
  const customDomain = match ? match[1] : null;
  if (!customDomain)
    throw Error("/error?errorCode=404&message=No custom domain found.");
  return customDomain;
}
