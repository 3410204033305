/** @jsxImportSource theme-ui */
import useUser from "@bottlebooks/gatsby-plugin-firebase-auth/src/useUser";
import type { UserProfileSchema } from "@bottlebooks/gatsby-plugin-firebase-auth/src/useUserProfile";
import { userToProfile } from "@bottlebooks/gatsby-plugin-firebase-auth/src/useUserProfile";
import useUserProfile from "@bottlebooks/gatsby-plugin-firebase-auth/src/useUserProfile";
import { Trans, t } from "@lingui/macro";
import { getApp } from "firebase/app";
import {
  OAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { useEffect, useState } from "react";
import type { EmailSchema } from "./Login.EnterEmail";
import { emailInitialSchema } from "./Login.EnterEmail";
import type { PasswordSchema } from "./Login.EnterPassword";
import { passwordInitialSchema } from "./Login.EnterPassword";
import type { FormikHelpers } from "formik";
import { useLingui } from "@lingui/react";
import { useCollectionLayout } from "../CollectionLayoutProvider.next";

export type LoginMethod = "EMAIL" | "GOOGLE" | "SIGNIN_LINK";

export type SuccessContext = { logout: () => void };

export type SelectLoginMethodOptions = {
  onContinue: (method: LoginMethod) => void;
  collectionId?: string;
};

export type GoogleSignInOptions = {
  retries: number;
  onRetry: () => void;
  onBack: () => void;
};

export type GoogleSignInContext = {
  message?: string | JSX.Element;
};

export type SignInWithEmailOptions = {
  onBack: () => void;
  onContinue: (values: EmailSchema) => void;
};

export type RequestSigninLinkOptions = {
  onBack: () => void;
  onContinue: (values: EmailSchema) => void;
};

export type SignInWithEmailContext = {
  initialValues: EmailSchema;
};

export type RequestSigninLinkContext = {
  initialValues: EmailSchema;
};

export type EnterPasswordOptions = {
  onBack: (values: PasswordSchema) => void;
  onContinue: (
    values: PasswordSchema,
    formikBag: FormikHelpers<PasswordSchema>
  ) => void;
};

export type EnterPasswordContext = {
  initialValues: PasswordSchema;
};

type LoginState =
  | ["IDLE"]
  | ["LOADING_AUTH"]
  | ["LOADING_PROFILE"]
  | ["SELECTING_LOGIN_METHOD", SelectLoginMethodOptions]
  | ["SIGNING_IN_WITH_GOOGLE", GoogleSignInOptions, GoogleSignInContext]
  | ["SIGNING_IN_WITH_EMAIL", SignInWithEmailOptions, SignInWithEmailContext]
  | ["ENTERING_PASSWORD", EnterPasswordOptions, EnterPasswordContext]
  | [
      "REQUESTING_SIGNIN_LINK",
      RequestSigninLinkOptions,
      RequestSigninLinkContext
    ]
  | ["SIGNIN_LINK_SENT"]
  | ["CONFIRMING_EMAIL"]
  | ["ENTERING_PROFILE"]
  | ["ERROR", string | JSX.Element]
  | ["SUCCESS", { profile: UserProfileSchema }, SuccessContext];

export default function useLoginState() {
  const { i18n } = useLingui();
  const [state, setState] = useState<LoginState>(["IDLE"]);
  const [user, isLoadingUser, error] = useUser();
  const { userProfile, error: profileError, isLoading } = useUserProfile();
  const { collectionId } = useCollectionLayout();
  const [current, options] = state;
  // TODO Rewrite this to avoid useEffect for most of the time.
  // It has an issue where some states are old, while the dependencies are more current,
  // for example when the user is logged in but has no profile,
  // or when the user is already logged out, but the state is still logged in.
  // We can avoid this by using a well-designed state machine.
  useEffect(() => {
    const signal = { aborted: false };
    async function signInWithGoogle() {
      if (current !== "SIGNING_IN_WITH_GOOGLE") return;
      const provider = new OAuthProvider("google.com");
      provider.addScope("profile");
      provider.addScope("email");
      try {
        if (signal.aborted) return;
        const result = await signInWithPopup(getAuth(getApp()), provider);
        console.log(result);
        if (signal.aborted) return;
      } catch (error) {
        if (signal.aborted) return;
        console.log({ ...error });
        if (error.code === "auth/popup-closed-by-user") {
          return setState([
            "SIGNING_IN_WITH_GOOGLE",
            options,
            {
              message: (
                <Trans>
                  You closed the login box. Do you want to try again?
                </Trans>
              ),
            },
          ]);
        }
        setState([
          "SIGNING_IN_WITH_GOOGLE",
          options,
          { message: error.message },
        ]);
        // return setState(['ERROR', error.message]);
      }
    }
    signInWithGoogle();
    return () => {
      // console.log('aborting');
      signal.aborted = true;
    };
  }, [current, options]);
  useEffect(() => {
    if (isLoadingUser) return setState(["LOADING_AUTH"]);
    if (isLoading) return setState(["LOADING_PROFILE"]);
    if (profileError) return setState(["ERROR", profileError.message]);
    if (error) return setState(["ERROR", error.message]);
    if (!user) {
      return setState([
        "SELECTING_LOGIN_METHOD",
        selectLoginMethodOptions(setState, collectionId),
      ]);
    }
    if (userProfile) {
      return setState([
        "SUCCESS",
        { profile: userProfile },
        { logout: () => getAuth(getApp()).signOut() },
      ]);
    }
  }, [error, isLoading, isLoadingUser, profileError, user, userProfile, i18n]);
  console.log(state[0], state[1], state[2]);
  // Handle an edge case where the user is logged in but has no profile.
  if (current === "SUCCESS" && !userProfile)
    return ["LOADING_PROFILE"] as const;
  return state;
}

function selectLoginMethodOptions(
  send: (state: LoginState) => void,
  collectionId: string
): SelectLoginMethodOptions {
  return {
    onContinue: (method: LoginMethod) => {
      if (method === "EMAIL") {
        return send([
          "SIGNING_IN_WITH_EMAIL",
          signInWithEmailOptions(send),
          { initialValues: { ...emailInitialSchema.parse({}), collectionId } },
        ]);
      }
      if (method === "GOOGLE") {
        return send([
          "SIGNING_IN_WITH_GOOGLE",
          signInWithGoogleOptions(send, 0),
          {},
        ]);
      }
      if (method === "SIGNIN_LINK") {
        return send([
          "REQUESTING_SIGNIN_LINK",
          requestSigninLink(send),
          { initialValues: { ...emailInitialSchema.parse({}), collectionId } },
        ]);
      }
      exhaustiveCheck(method);
    },
  };
}

function signInWithGoogleOptions(
  send: (state: LoginState) => void,
  retries: number
) {
  return {
    retries,
    onRetry: () =>
      send([
        "SIGNING_IN_WITH_GOOGLE",
        signInWithGoogleOptions(send, retries + 1),
        {},
      ]),
    onBack: () =>
      send(["SELECTING_LOGIN_METHOD", selectLoginMethodOptions(send)]),
  };
}

function requestSigninLink(
  send: (state: LoginState) => void
): RequestSigninLinkOptions {
  return {
    onBack: () =>
      send(["SELECTING_LOGIN_METHOD", selectLoginMethodOptions(send)]),
    onContinue: (values) => {
      try {
        const actionCodeSettings = {
          // URL you want to redirect back to. The domain (www.example.com) for this
          // URL must be in the authorized domains list in the Firebase Console.
          url: `${window.origin}/en/collections/${values.collectionId}/validateSigninLink`,
          // This must be true.
          handleCodeInApp: true,
        };
        const { email } = passwordInitialSchema.parse(values);
        sendSignInLinkToEmail(getAuth(getApp()), email, actionCodeSettings)
          .then(() => {
            // The link was successfully sent. Inform the user.
            // Save the email locally so you don't need to ask the user for it again
            // if they open the link on the same device.
            window.localStorage.setItem("emailForSignIn", email);
            return send(["SIGNIN_LINK_SENT"]);
            // ...
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ...
          });
      } catch (error) {
        // TODO: handle when password is missing.
        console.log({ ...error });
      }
    },
  };
}

function signInWithEmailOptions(
  send: (state: LoginState) => void
): SignInWithEmailOptions {
  return {
    onBack: () =>
      send(["SELECTING_LOGIN_METHOD", selectLoginMethodOptions(send)]),
    onContinue: (values) =>
      send([
        "ENTERING_PASSWORD",
        enterPasswordOptions(send),
        { initialValues: passwordInitialSchema.parse(values) },
      ]),
  };
}

function enterPasswordOptions(
  send: (state: LoginState) => void
): EnterPasswordOptions {
  return {
    onBack: (values) => {
      return send([
        "SIGNING_IN_WITH_EMAIL",
        signInWithEmailOptions(send),
        { initialValues: emailInitialSchema.parse(values) },
      ]);
    },
    onContinue: async (values, formikBag) => {
      try {
        const result = await signInWithEmailAndPassword(
          getAuth(getApp()),
          values.email,
          values.password
        );
        return send([
          "SUCCESS",
          { profile: userToProfile.parse(result.user) },
          { logout: () => getAuth(getApp()).signOut() },
        ]);
      } catch (error) {
        console.log({ ...error });
        switch (error.code) {
          case "auth/user-not-found": {
            try {
              const result = await createUserWithEmailAndPassword(
                getAuth(getApp()),
                values.email,
                values.password
              );
              console.log(result);
              return send(["ENTERING_PROFILE"]);
            } catch (error) {
              console.log({ ...error });
              switch (error.code) {
                case "auth/weak-password":
                  formikBag.setSubmitting(false);
                  formikBag.setErrors({
                    password: t`Your password is too weak.`,
                  });
                  return;

                default:
                  console.log({ ...error });
                  formikBag.setSubmitting(false);
                  formikBag.setErrors({ password: error.message });
                  return;
              }
            }
          }
          case "auth/wrong-password":
            formikBag.setSubmitting(false);
            formikBag.setErrors({
              password: t`Your password is incorrect.`,
            });
            return;
          default:
            formikBag.setSubmitting(false);
            formikBag.setErrors({ password: error.message });
            return;
        }
      }
    },
  };
}

function exhaustiveCheck(_: never): never {
  throw new Error("Unhandled state");
}
