import {
  fromNavigator,
  fromStorage,
  fromUrl,
  multipleDetect,
} from "@lingui/detect-locale";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { LoaderFunctionArgs } from "react-router-dom";
import { z } from "zod";
import { graphql } from "~/gql";
import graphQLClient from "~/graphQLClient";
import queryClient from "~/queryClient";
import { redirect, useParams } from "~/router";

const paramsSchema = z.object({ shortCodeOrProductId: z.string() });

function query(params: { shortCodeOrProductId: string }) {
  return queryOptions({
    queryKey: ["sc", params.shortCodeOrProductId],
    // throwOnError: true,
    queryFn: async () => {
      if (params.shortCodeOrProductId.length === 24) {
        try {
          const result = await graphQLClient.request(
            graphql(`
              query ShortCodePage_ProductId($productId: ID!) {
                productTasks: _unstable_allProductTasksByTemplateType(
                  experimentalKey: "bernhard@bottlebooks.me: @bottlebooks/bottlebooks-site/src/pages/[locale]/sc.[shortCode].tsx"
                  productId: $productId
                  templateType: PRODUCTPAGE
                ) {
                  productId
                  collectionId
                  taskId
                  collection {
                    locales: locales(format: RAW)
                  }
                }
              }
            `),
            { productId: params.shortCodeOrProductId }
          );
          const productTasks = result.productTasks;
          if (!productTasks.length) {
            throw new Error(
              `We couldn't find a task for the product ${params.shortCodeOrProductId}.`
            );
          }
          if (productTasks.length > 1) {
            throw new Error(
              `We found multiple tasks for the product ${params.shortCodeOrProductId}.`
            );
          }
          const productTask = productTasks[0];
          return {
            found: true as const,
            locales: productTask.collection.locales,
            collectionId: productTask.collectionId,
            taskId: productTask.taskId,
            productId: productTask.productId,
          };
        } catch (e) {
          // Do nothing
          console.warn(e);
        }
      }

      const result = await graphQLClient.request(
        graphql(`
          query ShortCodePage_ShortCode($shortCode: String!) {
            productTasks: _unstable_allProductTasksByCustomQuestionKey(
              experimentalKey: "bernhard@bottlebooks.me: @bottlebooks/bottlebooks-site/src/pages/[locale]/sc.[shortCode].tsx"
              key: "shortcode"
              value: $shortCode
              templateType: PRODUCTPAGE
            ) {
              productId
              collectionId
              taskId
              collection {
                locales: locales(format: RAW)
              }
              productHeader {
                name
                producerName
              }
            }
          }
        `),
        { shortCode: params.shortCodeOrProductId }
      );
      const productTasks = result.productTasks;
      if (!productTasks.length) {
        return { found: false as const };
      }
      if (productTasks.length > 1) {
        throw new Error(
          `We found multiple tasks for the product ${params.shortCodeOrProductId}.`
        );
      }
      const productTask = productTasks[0];
      return {
        found: true as const,
        locales: productTask.collection.locales,
        collectionId: productTask.collectionId,
        taskId: productTask.taskId,
        productId: productTask.productId,
      };
    },
  });
}

export async function Loader(args: LoaderFunctionArgs) {
  const params = paramsSchema.parse(args.params);
  const data = await queryClient.fetchQuery(query(params));
  console.log(data);
  if (data.found) {
    const detectedLocales = multipleDetect(
      fromUrl("locale"),
      fromStorage("locale"),
      fromNavigator(),
      data.locales[0]
    );
    const locale =
      detectedLocales.find((locale) => data.locales.includes(locale)) ??
      data.locales[0];
    throw redirect(
      "/:locale/c/:collectionId/tasks/:taskId/products/:productId",
      {
        params: {
          locale,
          collectionId: data.collectionId,
          taskId: data.taskId,
          productId: data.productId,
        },
      }
    );
  }
  return null;
}

export default function ShortCodePage() {
  // TODO Show something in case we couldn't find the shortcut or productId.
  return null;
}
