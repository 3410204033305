import { useCollectionLayout } from "@bottlebooks/bottlebooks-site-base/src/components/CollectionLayoutProvider.next";
import ListsPage from "@bottlebooks/bottlebooks-site-lists/src/components/ListsPage";
import { z } from "zod";
import { useParams } from "~/router";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
});

export default function ProfileRoute({ ...rest }: {}) {
  const params = useParams("/:locale/my/lists");
  const { locale } = paramsSchema.parse(params);
  const { collectionId } = useCollectionLayout();
  return <ListsPage locale={locale} collectionId={collectionId} {...rest} />;
}
