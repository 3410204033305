/** @jsxImportSource theme-ui */
import {
  Box,
  Col,
  Container,
  SemiTitle,
  Text,
} from "@bottlebooks/gatsby-theme-base/src";
import { Fragment } from "react";
import { FragmentType, graphql, useFragment } from "~/gql";
import ContentRow from "../ContentRow";
import Representatives from "../ExhibitorPage/Representatives.next";
import ProductDetailsSection from "./ProductDetailsSection.next";

export const fragment = graphql(`
  fragment ProductSections_RegisteredProduct on RegisteredProduct {
    productId
    registration {
      ...Representatives
    }
    product {
      ...ProductDetailsSection
    }
    # ...NoteableBanner
    listOfStockists: customFieldValue(key: "listOfStockists") {
      ...FieldValue
    }
    currentlyAvailableInUkRetail: customFieldValue(
      key: "currentlyAvailableInUkRetail"
    ) {
      ...FieldValue
    }
  }
`);

/**
 * A component to contain all the product details sections.
 * It can be used to prepend or append some content in shadowed components.
 * @param {object} props
 * @param {object} props.exhibitor The exhibitor.
 * @param {object} props.product The product.
 * @param {object} props.eventId The eventId.
 * @returns
 */
export default function ProductSections({
  data,
}: {
  data: FragmentType<typeof fragment>;
}) {
  const registeredProduct = useFragment(fragment, data);
  const registration = registeredProduct.registration;
  const product = registeredProduct.product;
  return (
    <Fragment>
      <Container sx={{ marginBottom: 3 }}>
        <ContentRow>
          <Col flex>
            <Stockists data={data} />
          </Col>
        </ContentRow>
      </Container>
      <Container sx={{ width: "100%" }}>
        <ContentRow>
          <Col flex>
            <Representatives data={registration} sx={{ marginBottom: 3 }} />
          </Col>
        </ContentRow>
        <ContentRow>
          <ProductDetailsSection data={product} />
        </ContentRow>
      </Container>
      {/* <NoteableBanner product={product} /> */}
    </Fragment>
  );
}

function Stockists({ data }: { data: FragmentType<typeof fragment> }) {
  const registeredProduct = useFragment(fragment, data);
  const availableInUk = !!registeredProduct.currentlyAvailableInUkRetail?.value;
  if (!availableInUk) return null;
  const stockistDetails = registeredProduct.listOfStockists?.text;
  const hasStockistDetails = !!stockistDetails;
  return (
    <Box
      sx={{
        backgroundColor: "#fed40b",
        padding: 3,
      }}
    >
      <SemiTitle
        sx={{
          marginTop: 1,
          color: "#333",
          padding: 0,
        }}
      >
        Available in UK Retail
      </SemiTitle>
      {availableInUk && hasStockistDetails && (
        <Text variant="large" sx={{ color: "#333" }}>
          {stockistDetails}
        </Text>
      )}
      {availableInUk && !hasStockistDetails && (
        <Text variant="large" sx={{ color: "#333" }}>
          Contact exhibitor for stockists
        </Text>
      )}
    </Box>
  );
}
