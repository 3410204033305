/** @jsxImportSource theme-ui */

import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Stack,
} from "@bottlebooks/gatsby-theme-base/src";
import { defineMessage } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { graphql } from "~/gql";
import React from "react";
import { ExhibitorsList } from "../ExhibitorsList";
import ActiveFiltersBar from "../Filters/ActiveFiltersBar";
import FilterField from "../Filters/FilterField";
import {
  filtersCleared,
  filterToggled,
  searchTermChanged,
  sortChanged,
} from "../Filters/filterReducer";
import Filters, { useFiltersDisplay } from "../Filters/Filters";
// eslint-disable-next-line import/no-unresolved
import useIndex from "../Filters/useIndex";
import useLocationSyncedState from "../Filters/useLocationSyncedState";
// import DownloadMyBookmarks from '../ProductsPage/DownloadMyBookmarks';
import Sort from "../ProductsPage/Sort";
// import SEO from "../seo.js";
import EmptyState from "./EmptyState";
// eslint-disable-next-line import/no-unresolved
import useExhibitorFilters from "./exhibitorFilters.next";
import ExhibitorsPageCards, { groupData } from "./ExhibitorsPageCards";
import ExhibitorsPageTitle from "./ExhibitorsPageTitle";
import useExhibitorSearch from "./useExhibitorSearch.next";

const PAGE_SIZE = 30;

export default function ExhibitorsPage({
  exhibitors,
  collectionId,
  location,
  locale,
}) {
  const { i18n } = useLingui();
  const filterConfig = useExhibitorFilters();
  const [state, dispatch] = useLocationSyncedState({
    hash: window.location.hash,
    state: window.history.state,
  });
  const searched = useExhibitorSearch(exhibitors, state.searchTerm?.toString()); // TODO (solve differently) with SSR, sometimes the searchTerm is an array.
  const sortings = Object.entries(filterConfig.sortings).map(
    ([key, sorting]) => ({ key, ...sorting })
  );
  const activeSorting =
    sortings.find((sorting) => sorting.key === state.sort) || sortings[0];
  const sort = activeSorting.key;

  const { getToggleProps, getFiltersProps, isVisibleOnDesktop } =
    useFiltersDisplay();

  // Filter the exhibitors by term and facets.
  const [limit, setLimit] = React.useState(PAGE_SIZE);
  const index = useIndex(searched, filterConfig);
  const filters = Object.fromEntries(
    Object.keys(filterConfig.aggregations).map((key) => [key, state[key] || []])
  );
  const { data, pagination } = index.search({ per_page: limit, sort, filters });
  // console.log(filterConfig, data, pagination, timings);
  const showMore = React.useCallback(
    () => setLimit((limit) => limit + PAGE_SIZE),
    []
  );
  /**
   * @param {{key: string; doc_count: number; selected: boolean; }} bucket
   */
  function toggleFilter(aggregation, bucket) {
    dispatch(filterToggled(aggregation.name, bucket.key));
    setLimit(PAGE_SIZE);
    // if (!bucket.selected) setFiltersAreVisible(false);
  }
  const mainColumn = {
    gridColumnStart: [1, isVisibleOnDesktop ? 2 : 1],
    gridColumnEnd: -1,
  };

  const hasMore = pagination.total > data.items.length;
  const page = { path: location.href };
  return (
    <Box sx={{ backgroundColor: "light" }}>
      {/* <SEO
          page={page}
          title={i18n._(defineMessage({ message: `Exhibitors` }))}
        /> */}
      <Container fluid sx={{ paddingTop: 3, paddingBottom: 6 }}>
        <Stack>
          <ActiveFiltersBar
            aggregations={data.aggregations}
            definitions={filterConfig.aggregations}
            onToggleFilter={toggleFilter}
            onClearFilters={() => dispatch(filtersCleared())}
          />
          <Flex
            gap={2}
            align="baseline"
            justify="space-between"
            sx={{ flexDirection: ["column", "row"] }}
          >
            <ExhibitorsPageTitle
              totalCount={exhibitors.length}
              count={pagination.total}
            />
            {/* <DownloadMyBookmarks
                collectionId={collectionId}
                locale={locale}
              /> */}
          </Flex>
          <Grid
            width={254}
            repeat="fill"
            sx={{ alignItems: "center", gridAutoFlow: "dense" }}
          >
            <Sort
              sortings={sortings}
              value={sort}
              onChange={(value) => dispatch(sortChanged(value))}
              sx={{ gridColumn: "-2/-1", textAlign: "right" }}
            />
            <FilterField
              placeholder={i18n._(
                defineMessage({ message: "Enter wine or producer name" })
              )}
              value={state.searchTerm}
              onChange={(value) => {
                setLimit(PAGE_SIZE);
                return dispatch(searchTermChanged(value));
              }}
              variant="large"
              sx={{
                borderColor: "borderSecondary",
                gridColumn: ["1/-1", "2/-2"],
              }}
            />
            <Filters.ToggleDesktop {...getToggleProps()} />
            <Filters.ToggleMobile {...getToggleProps()} />
          </Grid>
          <Grid width={254} repeat="fill" sx={{ alignItems: "flex-start" }}>
            <Filters
              key={state.searchTerm}
              aggregations={data.aggregations}
              definitions={filterConfig.aggregations}
              onToggleFilter={toggleFilter}
              totalCount={exhibitors.length}
              count={pagination.totalCount}
              sx={{
                // Copied from online directory code
                // Keeps menu on screen
                maxHeight: [null, "100vh"],
                overflowY: [null, "auto"],
                position: [null, "sticky"],
                top: 0,
              }}
              {...getFiltersProps()}
            />

            {!pagination.total ? (
              <EmptyState sx={mainColumn} />
            ) : (
              <ExhibitorsList
                hasMore={hasMore}
                showMore={showMore}
                width={
                  groupData({
                    group: activeSorting.group,
                    exhibitors: data.items,
                  })
                    ? "100%"
                    : 254
                }
                sx={{ gridAutoFlow: "dense", ...mainColumn }}
              >
                <ExhibitorsPageCards
                  exhibitors={data.items}
                  group={activeSorting.group}
                  groupHeader={activeSorting.groupHeader}
                />
              </ExhibitorsList>
            )}
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
}

export const fragment = graphql(/* GraphQL */ `
  fragment bb_ExhibitorsPage on Bottlebooks_Exhibitor {
    exhibitorId
    ...bb_ExhibitorCard
    ...useLink_bb_Exhibitor
  }

  fragment bb_ExhibitorsPage_RegisteredBrand on Bottlebooks_RegisteredBrand {
    ...bb_ExhibitorCard_RegisteredBrand
  }

  fragment bb_ExhibitorsPage_Registration on Bottlebooks_Registration {
    ...bb_ExhibitorCard_Registration
  }

  fragment ExhibitorsPage_first on Exhibitor {
    ...ExhibitorCard_first
  }

  fragment ExhibitorsPage on Exhibitor {
    exhibitorId
    ...ExhibitorCard
  }

  fragment ExhibitorsPage_Event on Event {
    ...EventBanner
  }
`);
