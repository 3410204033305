import { useCollectionLayout } from "./components/CollectionLayoutProvider.next";
import env from "~/env";

/**
 * Uploads an image to the REST API
 */
export default function useImageUpload() {
  const { collectionId } = useCollectionLayout();

  async function uploadImage(
    profileImage: File | null | undefined
  ): Promise<string | null> {
    if (!profileImage) return null;
    // Submit image to REST API
    const uploadFileRestUrl = `${env.VITE_API_URL}/pub/next/collections/${collectionId}/images`;
    const formData = new FormData();
    formData.append("file", profileImage);
    formData.append("contentType", profileImage.type);

    const response = await fetch(uploadFileRestUrl, {
      method: "POST",
      credentials: "omit",
      body: formData,
    });

    if (!response.ok) throw new Error("Request failed");

    const uploadImageData = await response.json();
    return uploadImageData.file.url || null;
  }
  return { uploadImage };
}
