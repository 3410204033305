import { Container, Stack, Text, Title } from "@mantine/core";
import { useSearchParams } from "react-router-dom";

export default function NotFound() {
  // Get the value "message" from the query string
  const [searchParams] = useSearchParams();
  const message = searchParams.get("message");
  if (message) {
    return (
      <Container
        h="100vh"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack align="center" justify="center">
          <Title order={1} size="10rem" fw={900} c="gray.5">
            404
          </Title>
          <Text size="xl" ta="center" maw={500} mb="xl">
            {message}
          </Text>
        </Stack>
      </Container>
    );
  }
  return (
    <Container
      h="100vh"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack align="center" justify="center">
        <Title order={1} size="10rem" fw={900} c="gray.5">
          404
        </Title>
        <Text size="xl" ta="center" maw={500} mb="xl">
          You have found a secret place. Unfortunately, this is only a 404 page.
          You may have mistyped the address, or the page has been moved to
          another URL.
        </Text>
      </Stack>
    </Container>
  );
}
