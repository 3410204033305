/** @jsxImportSource theme-ui */
import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import type { BoxProps } from "theme-ui";
import LoadingAuth from "./Login.Loading";
import SelectLoginMethod from "./Login.SelectMethod";
import type { LoginMethod } from "./Login.useState";
import useLoginState from "./Login.useState";
import LoginSuccess from "./Login.Success";
import GoogleSignIn from "./Login.GoogleSignIn";
import EnterEmail from "./Login.EnterEmail";
import EnterPassword from "./Login.EnterPassword";
import RequestSigninLink from "./Login.RequestSigninLink";
import SigninLinkSent from "./Login.SigninLinkSent";

type LoginProps = Omit<BoxProps, "sx"> & {
  availableMethods: LoginMethod[];
  onSuccess?: () => void;
  prepend?: ReactNode;
  /**
   * Prepend this content only on the initial screens.
   * Use this for warning messages for example.
   */
  initialPrepend?: ReactNode;
  append?: ReactNode;
};

export default function LoginState({
  availableMethods,
  onSuccess,
  prepend,
  initialPrepend = prepend,
  append,
  children,
  ...rest
}: LoginProps) {
  const [state, result, context] = useLoginState();
  console.log("state", state, result);
  switch (state) {
    case "IDLE":
      return <LoadingAuth prepend={initialPrepend} append={append} {...rest} />;

    case "LOADING_AUTH":
      return <LoadingAuth prepend={initialPrepend} append={append} {...rest} />;

    case "SELECTING_LOGIN_METHOD":
      return (
        <SelectLoginMethod
          prepend={initialPrepend}
          availableMethods={availableMethods}
          onContinue={result.onContinue}
          append={append}
          {...rest}
        />
      );

    case "SIGNING_IN_WITH_GOOGLE":
      return (
        <GoogleSignIn
          prepend={prepend}
          onBack={result.onBack}
          onRetry={result.onRetry}
          retries={result.retries}
          message={context.message}
          append={append}
          {...rest}
        />
      );

    case "SIGNING_IN_WITH_EMAIL":
      console.log("entering email");

      return (
        <EnterEmail
          prepend={prepend}
          onBack={result.onBack}
          onContinue={result.onContinue}
          initialValues={context.initialValues}
          append={append}
          {...rest}
        />
      );

    case "REQUESTING_SIGNIN_LINK":
      return (
        <RequestSigninLink
          prepend={prepend}
          onBack={result.onBack}
          onContinue={result.onContinue}
          initialValues={context.initialValues}
          append={append}
          {...rest}
        />
      );
    case "SIGNIN_LINK_SENT":
      return <SigninLinkSent prepend={prepend} append={append} {...rest} />;

    case "ENTERING_PASSWORD":
      console.log("entering password");
      return (
        <EnterPassword
          prepend={prepend}
          onBack={result.onBack}
          onContinue={result.onContinue}
          initialValues={context.initialValues}
          append={append}
          {...rest}
        />
      );

    case "LOADING_PROFILE":
      return <LoadingAuth prepend={prepend} append={append} {...rest} />;

    case "ENTERING_PROFILE":
      // TODO: Implement profile creation.
      return null;

    case "SUCCESS":
      return (
        <LoginSuccess context={context} profile={result.profile} {...rest}>
          {children}
        </LoginSuccess>
      );

    case "CONFIRMING_EMAIL":
      return <div>TODO: Implement email confirmation</div>;

    case "ERROR":
      return <div>Error: {result}</div>;

    default:
      return exhaustiveCheck(state);
  }
}

function exhaustiveCheck(_: never): never {
  throw new Error("Unhandled state");
}

export const LoginContext = createContext<
  | Extract<ReturnType<typeof useLoginState>, ["SUCCESS", unknown, unknown]>[2]
  | null
>(null);

export function useLoginContext() {
  const context = useContext(LoginContext);
  if (!context) {
    throw new Error("useLoginContext must be used within a Login");
  }
  return context;
}
