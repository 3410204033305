import { useCollectionLayout } from "@bottlebooks/bottlebooks-site-base/src/components/CollectionLayoutProvider.next";
import ProfilePage from "@bottlebooks/bottlebooks-site-bookmarks/src/components/ProfilePage";
import { z } from "zod";
import { useParams } from "~/router";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
});

export default function ProfileRoute({ ...rest }: {}) {
  const params = useParams("/:locale/my/profile");
  const { collectionId } = useCollectionLayout();
  const parsed = paramsSchema.parse(params);
  return (
    <ProfilePage
      locale={parsed.locale}
      collection={{ collectionId }}
      {...rest}
    />
  );
}
