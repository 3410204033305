/** @jsxImportSource theme-ui */
import {
  Box,
  FacebookIcon,
  Flex,
  Grid,
  InstagramIcon,
  Label,
  Text,
  TwitterIcon,
  YoutubeIcon,
} from "@bottlebooks/gatsby-design-system";
import { BottlebooksLogo, Link } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
// @ts-ignore TS doesn't like direct image imports.
import backToTop from "@bottlebooks/bottlebooks-site-base/src/assets/back-to-top.svg";
import useLink from "../../useLink";

export default function WinesFromSpainTop100Footer({
  children,
  homePath = "",
  ...rest
}: {
  children: React.ReactNode;
  homePath: string;
}) {
  return (
    <Wrapper {...rest}>
      <Top sx={{ marginTop: 4 }}>{children}</Top>
      {/* <Bottom /> */}
      <img
        src={`https://res.cloudinary.com/bottlebooks/image/upload/bottlebooks/5f6c8ff918b64f467d993269/2024_12_13_9_55_24-pastilla-CO-UE-Minis-Hacienda-Fondos-Europeos-ICEX-ing-2468.png`}
        width="100%"
      />
      <Bottom />
    </Wrapper>
  );
}

export function Wrapper({ children, ...rest }: { children: React.ReactNode }) {
  return (
    <Box
      as="footer"
      sx={{
        variant: "styles.Footer",
        paddingY: [2, 4],
        paddingX: [2, 5],
        backgroundColor: "background",
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}

export function Top({ children, ...rest }: { children: React.ReactNode }) {
  function handleScrollToTop(e) {
    window.scrollTo({ top: 0, behavior: "smooth" });
    e.preventDefault();
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        paddingBottom: [2, 4],
        borderBottom: 1,
        borderColor: "border",
      }}
      {...rest}
    >
      {children}
      <Link
        href="#"
        onClick={handleScrollToTop}
        sx={{ marginLeft: "auto", color: "black" }}
      >
        <Label>
          <Trans>Back to the top</Trans>{" "}
          <img src={backToTop} alt="" sx={{ verticalAlign: "middle" }} />
        </Label>
      </Link>
    </Box>
  );
}
function Bottom({ ...rest }) {
  return (
    <Flex align="baseline" gap={2} sx={{ paddingTop: [2, 4] }} {...rest}>
      <Text variant="smallest" sx={{ color: "lightText" }}>
        <Flex align="center">
          <Trans>Powered by</Trans>{" "}
          <Link
            href="https://www.bottlebooks.me"
            external
            sx={{ textTransform: "uppercase" }}
          >
            <BottlebooksLogo title="Bottlebooks®" />
          </Link>
        </Flex>
        <Text variant="smallest">
          <Trans>Data management for the wine and drinks industry</Trans>
        </Text>
      </Text>

      <Text variant="smallest" sx={{ marginLeft: "auto", color: "lightText" }}>
        2021
      </Text>
    </Flex>
  );
}
