/** @jsxImportSource theme-ui */
import { Button, DownloadIcon } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { z } from "zod";
import { FragmentType, graphql, useFragment } from "~/gql";

const fragment = graphql(`
  fragment RegistrationInfoSheetButton on SingleRegistration {
    collectionId
    collection {
      site {
        templateSettings {
          name
          value
        }
      }
    }
    companyId
    name
  }
`);

const siteConfigSchema = z.object({
  registrationInfoSheetTemplateId: z
    .string()
    .optional()
    .default("brn:print:bottlebooks:me.bottlebooks.standardCatalog2"),
});

export default function RegistrationInfoSheetButton({
  exhibitor: data,
  ...rest
}: {
  exhibitor: FragmentType<typeof fragment>;
}) {
  const registration = useFragment(fragment, data);
  const siteConfig = siteConfigSchema.parse(
    Object.fromEntries(
      (registration.collection?.site?.templateSettings ?? []).map(
        ({ name, value }) => [name, value]
      )
    )
  );
  return (
    <Button
      href={`https://app.bottlebooks.me/pdf/reg/${registration.collectionId}/${
        registration.companyId
      }/t/${encodeURIComponent(
        siteConfig.registrationInfoSheetTemplateId
      )}/f/${encodeURIComponent(registration.name)}?inline=1`}
      target="_blank"
      rel="noopener noreferrer"
      variant="outline"
      sx={{ textAlign: "center" }}
      {...rest}
    >
      <DownloadIcon
        size="small"
        sx={{ marginRight: 2, color: "currentColor" }}
      />
      <Trans>Download info sheet</Trans>
    </Button>
  );
}
