import { gql } from "urql";

// // The CMS is almost always active, but pointing to a collection without any data
// // Therefore we use the baseMenuQuery to fall back to the event data.
export default function mapToMenuItems({
  site,
  basePathname,
}: {
  site: {
    frontpage: {
      __typename: string;
      id: string;
      slug: { current: string };
      url?: string;
    };
    mainNavigation: {
      name: string;
      url?: string;
      page: {
        __typename: string;
        type: string;
        id: string;
        slug?: { current: string };
        url?: string;
      };
    }[];
  };
  basePathname: string;
}) {
  if (!site.mainNavigation)
    return [
      {
        name: "No menu items are configured",
        type: "otherUrl",
        url: null,
      },
    ];
  const mappedMenuItems = site.mainNavigation?.map((menuItem) => {
    switch (menuItem.page.__typename) {
      case "BottlebooksPage":
        // Landing page
        // There is a standard page type of landing page that users
        // can select. It is necessary because the landing page should
        // not have a slug.
        if (menuItem.page.type === "")
          return {
            name: menuItem.name,
            url: basePathname,
            type: "otherUrl",
          };
        else
          return {
            name: menuItem.name,
            // url is automatically generated by the directory
            // url: getLink(menuItem.page),
            type: menuItem.page.type,
          };
      case "Page":
        // Front page url is always "/"
        if (menuItem.page.slug?.current === site.frontpage.slug.current)
          return {
            name: menuItem.name,
            url: basePathname,
            type: "otherUrl",
          };
        return {
          name: menuItem.name,
          url: getLink({ page: menuItem.page, basePathname }),
          type: "otherUrl",
        };
      case "ExternalPage":
        return {
          name: menuItem.name,
          url: getLink({ page: menuItem.page, basePathname }),
          type: menuItem.page.type,
        };
      default:
        return menuItem;
    }
  });

  const menuItems = mappedMenuItems?.filter((item) => item.name || item.url);
  return menuItems;
}

/**
 * Generates a link for a page.
 */
function getLink({
  page,
  basePathname,
}: {
  page: {
    __typename: string;
    id: string;
    slug?: { current: string };
    url?: string;
  };
  basePathname: string;
}) {
  switch (page.__typename) {
    case "BottlebooksPage":
      // This value needs to be dynamically calculated based on context
      // return path(page.slug.current);
      return null;
    case "Page":
      return `${basePathname}/${page.slug?.current}`;
    case "ExternalPage":
      return page.url;
    default:
      return null;
  }
}

// /**
//  * Not sure if we need this anymore. Favorites is now a permanent item
//  * in the header.
//  * Keeping because it is an interesting pattern.
//  * @returns
//  */
// function useMenuEnhancer() {
//   const { favorites } = useFavorites();
//   return function enhanceMenuItem(item) {
//     if (item.type === "tastingList")
//       return { ...item, counter: favorites?.length };
//     return item;
//   };
// }

mapToMenuItems.fragment = gql`
  fragment mapToMenuItems on Site {
    collectionId
    frontpage {
      __typename
      id: _id
      slug {
        current
      }
    }
    mainNavigation {
      name
      page {
        __typename
        ... on BottlebooksPage {
          type
          id: _id
        }
        ... on Page {
          id: _id
          slug {
            current
          }
        }
        ... on ExternalPage {
          title
          type
          url
        }
      }
    }
  }
`;
