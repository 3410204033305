import { Container, Stack, Text, Title } from "@mantine/core";
import { useSearchParams } from "react-router-dom";

export default function NotFound() {
  // Get the value "message" from the query string
  const [searchParams] = useSearchParams();
  const errorCode = searchParams.get("errorCode");
  const message = searchParams.get("message");

  return (
    <Container
      h="100vh"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack align="center" justify="center">
        <Title order={1} size="10rem" fw={900} c="gray.5">
          {errorCode ?? "500"}
        </Title>
        <Text size="xl" ta="center" maw={500} mb="xl">
          {message ?? "No message was provided."}
        </Text>
      </Stack>
    </Container>
  );
}
