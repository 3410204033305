import EventProductPage from "@bottlebooks/bottlebooks-site-base/src/components/EventProductPage/EventProductPage.next";
import { Container, Stack, Text, Title } from "@mantine/core";
import { queryOptions, useQuery } from "@tanstack/react-query";
import {
  isRouteErrorResponse,
  LoaderFunctionArgs,
  useRouteError,
} from "react-router-dom";
import { z } from "zod";
import { graphql } from "~/gql";
import graphQLClient from "~/graphQLClient";
import queryClient from "~/queryClient";
import { useParams } from "~/router";
import LanguageSwitcher from "../_LanguageSwitcher";
import { Box } from "@bottlebooks/gatsby-theme-base/src";

const paramsSchema = z.object({
  locale: z.enum(["de", "en", "es", "fr"]),
  collectionId: z.string().length(24),
  taskId: z.string().length(24),
  productId: z.string().length(24),
});

function query(params: {
  locale: z.output<typeof paramsSchema>["locale"];
  collectionId: string;
  taskId: string;
  productId: string;
}) {
  return queryOptions({
    queryKey: [
      params.locale,
      "collection",
      params.collectionId,
      "tasks",
      params.taskId,
      "products",
      params.productId,
    ],
    throwOnError: true,
    queryFn: async () => {
      const result = await graphQLClient.request(
        graphql(`
          query ProductInfoPage(
            $collectionId: ID!
            $taskId: ID!
            $productId: ID!
            $locale: ContentLocale
          ) {
            collection(collectionId: $collectionId, locale: $locale) {
              ...LanguageSwitcher
              product: product_2024(productId: $productId, versionTag: HEAD) {
                task(taskId: $taskId) {
                  collectionProduct {
                    allRegisteredProducts {
                      productId
                      registrationId
                      ...EventProductPage_RegisteredProduct
                    }
                  }
                }
              }
            }
          }
        `),
        {
          locale: params.locale,
          collectionId: params.collectionId,
          taskId: params.taskId,
          productId: params.productId,
        }
      );
      const collection = result.collection;
      if (!collection)
        throw new Error(
          `We couldn't find the collection ${params.collectionId}.`
        );
      const task = collection.product.task;
      if (!task)
        throw new Error(
          `We couldn't find the task ${params.taskId} in collection ${params.collectionId}.`
        );
      const product = task.collectionProduct;
      return {
        task,
        product,
        collection,
      };
    },
  });
}

export async function Loader(args: LoaderFunctionArgs) {
  const params = paramsSchema.parse(args.params);
  await queryClient.prefetchQuery(query(params));
  return null;
}

export default function ProductInfoPage() {
  const params = paramsSchema.parse(
    useParams("/:locale/c/:collectionId/tasks/:taskId/products/:productId")
  );
  const { data } = useQuery(query(params));
  const registeredProduct = data?.product.allRegisteredProducts[0];
  if (!registeredProduct) return null;
  console.log(data);

  return (
    <>
      <Box sx={{ textAlign: "right" }}>
        <LanguageSwitcher data={data.collection} />
      </Box>
      <EventProductPage
        key={`${registeredProduct.registrationId}-${registeredProduct.productId}`}
        data={registeredProduct}
        otherProducts={undefined}
        otherProductsName={undefined}
        relatedProducts={undefined}
        // TODO: implement previous and next for paging through products.
        previous={undefined}
        next={undefined}
      />
    </>
  );
}

export function Catch() {
  const error = useRouteError();
  console.log(error);
  if (isRouteErrorResponse(error)) {
    console.log(error.data);
    return (
      <Container
        h="100vh"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack align="center" justify="center">
          <Title order={1} size="10rem" fw={900} c="gray.5">
            {error.status ?? "500"}
          </Title>
          <Text size="xl" ta="center" maw={500} mb="xl">
            {error.statusText ?? "No message was provided."}
          </Text>
        </Stack>
      </Container>
    );
  }
  if (error instanceof Error && error.stack) {
    console.log(error.stack);
  }
  if (isGraphQLError(error)) {
    return (
      <Container
        h="100vh"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack align="center" justify="center">
          <Title order={1} size="10rem" fw={900} c="gray.5">
            {error.response.status ?? "500"}
          </Title>
          {error.response.errors?.map((error, index) => (
            <Text key={index} size="xs" ta="center" maw={500} mb="xl">
              {error.message} Path: {error.path.join(".")}
            </Text>
          ))}
        </Stack>
      </Container>
    );
  }
  if (!(error instanceof Error)) {
    return (
      <Container
        h="100vh"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack align="center" justify="center">
          <Title order={1} size="10rem" fw={900} c="gray.5">
            {"500"}
          </Title>
          <Text size="xs" ta="center" maw={500} mb="xl">
            An unknown error occurred.
          </Text>
        </Stack>
      </Container>
    );
  }

  return (
    <Container
      h="100vh"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack align="center" justify="center">
        <Title order={1} size="10rem" fw={900} c="gray.5">
          {"status" in error &&
          (typeof error.status === "number" || typeof error.status === "string")
            ? error.status ?? "500"
            : "500"}
        </Title>
        <Text size="xs" ta="center" maw={500} mb="xl">
          {error.message ?? "No message was provided."}
        </Text>
      </Stack>
    </Container>
  );
}

type GraphQLErrorResponse = {
  response: {
    status: number;
    errors: Array<{
      message: string;
      path: Array<string>;
    }>;
  };
};

function isGraphQLError(error: unknown): error is GraphQLErrorResponse {
  return (
    error instanceof Object &&
    "response" in error &&
    error.response instanceof Object &&
    "status" in error.response &&
    "errors" in error.response
  );
}
