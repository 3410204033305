import { Helmet } from "react-helmet";
import { FragmentType, graphql, useFragment } from "~/gql";

const fragment = graphql(`
  fragment SEO on Collection {
    name
    description
    localeCodes: locales(format: RAW)
  }
`);

const SEO = ({ data }: { data: FragmentType<typeof fragment> }) => {
  const collection = useFragment(fragment, data);
  const description = collection.description ?? collection.name ?? "";
  const title = collection.name ? `${collection.name} | Bottlebooks Site` : "";
  const url = window.location.href;

  return (
    <Helmet htmlAttributes={{ lang: collection.localeCodes[0] }}>
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={description} />

      <meta property="og:url" content={url} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
};

export default SEO;
