/** @jsxImportSource theme-ui */
import { Col } from "@bottlebooks/gatsby-theme-base/src";
import { FragmentType, graphql, useFragment } from "~/gql";
import useSiteConfig from "../useSiteConfig";
import BrandAnnualProduction from "./BrandAnnualProduction.next";
import BrandCertifications from "./BrandCertifications.next";
import BrandDescription from "./BrandDescription.next";
import BrandDistributors from "./BrandDistributors.next";
import BrandFounded from "./BrandFounded.next";
import BrandGallery from "./BrandGallery.next";
import BrandRegionSection from "./BrandRegionSection.next";
import BrandTourism from "./BrandTourism.next";
import BrandVideos from "./BrandVideos.next";
import BrandViticulturist from "./BrandViticulturist.next";
import BrandWinemaker from "./BrandWinemaker.next";
import PresentedBrands from "./PresentedBrands.next";

export const fragment = graphql(`
  fragment BrandDetailsSection on Profile {
    __typename
    name
    ...BrandDescription_Profile
    ...BrandTourism_Profile
    ...BrandRegionSection
    ...BrandCertifications
    ...BrandFounded_Profile
    ...BrandAnnualProduction_Profile
    ...BrandTourism_Profile
    ...BrandGallery_Profile
    ...BrandVideos_Profile
    ...BrandDistributors
    # ...BrandWinemaker_Profile
    # ...BrandContacts_Profile
  }
`);

const presentedBrandsFragment = graphql(`
  fragment BrandDetailsSection_PresentedBrand on RegisteredBrand {
    ...PresentedBrands
  }
`);

export default function BrandDetailsSection({
  data,
  presentedBrandsData,
  isPresentedBrandsVisible,
  ...rest
}: {
  data: FragmentType<typeof fragment> | null | undefined;
  isPresentedBrandsVisible?: boolean;
  presentedBrandsData: FragmentType<typeof presentedBrandsFragment>[];
} & React.HTMLAttributes<HTMLDivElement>) {
  const brand = useFragment(fragment, data);
  const brandProfile = brand?.__typename === "BrandProfile" ? brand : null;
  if (!brand) throw new Error("No brand.");
  const presentedBrands = useFragment(
    presentedBrandsFragment,
    presentedBrandsData
  );
  const { showDistributionDetails } = useSiteConfig();

  return (
    <Col flex {...rest}>
      <BrandDescription data={brand} />
      <BrandCertifications data={brand} />
      {isPresentedBrandsVisible ? (
        <PresentedBrands data={presentedBrands} />
      ) : null}
      {["all", "global"].includes(showDistributionDetails) && (
        <BrandDistributors brand={brand} />
      )}
      <BrandWinemaker data={brand} />
      <BrandViticulturist data={brand} />
      <BrandRegionSection data={brand} />
      <BrandFounded data={brand} />
      <BrandAnnualProduction data={brandProfile} />
      <BrandTourism data={brand} />
      <BrandGallery data={brand} />
      <BrandVideos data={brand} />
      {/* <BrandContacts brand={brand} /> */}
    </Col>
  );
}
