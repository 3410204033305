import { load } from "@bottlebooks/bottlebooks-site-base/src/wrapElement/lingui/i18n.config";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { LoaderFunctionArgs, Outlet } from "react-router-dom";
import { z } from "zod";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
});

export async function Loader(args: LoaderFunctionArgs) {
  const { locale } = paramsSchema.parse(args.params);
  load(locale);
  i18n.activate(locale);
  console.log("resolved locale", locale);
  return null;
}

export default function LocaleLayout() {
  return (
    <I18nProvider i18n={i18n}>
      <Outlet />
    </I18nProvider>
  );
}
