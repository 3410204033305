import SanityPage from "@bottlebooks/bottlebooks-site-cms/src/components/SanityPage";
import { allPageQuery } from "@bottlebooks/bottlebooks-site-cms/src/pages/cms-preview/pages/allPageQuery";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import { z } from "zod";
import fetchSanity from "~/fetchSanity";
import getInvalidationPath from "~/getRequestCacheKey";
import { graphql } from "~/gql";
import graphQLClient from "~/graphQLClient";
import queryClient from "~/queryClient";
import { useParams } from "~/router";
import getCollectionId from "./_getCollectionId";
import getCustomDomain from "./_getCustomDomain";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
  slug: z.string(),
});

function getQuery(
  params: z.infer<typeof paramsSchema> & { customDomain: string }
) {
  return queryOptions({
    queryKey: [params.locale, params.customDomain, params.slug],
    queryFn: async () => {
      const { locale, customDomain, slug } = params;
      const result = await graphQLClient.request(
        graphql(/* GraphQL */ `
          query CMSPageByCustomDomain(
            $customDomain: String!
            $locale: ContentLocale
          ) {
            collection: _unstable_collectionByCustomDomain(
              experimentalKey: "jonathan@bottlebooks.me: @bottlebooks/bottlebooks-site/src/pages/[locale]/_layout.tsx"
              customDomain: $customDomain
              locale: $locale
            ) {
              collectionId
              site {
                templateSettings {
                  name
                  value
                }
              }
            }
          }
        `),
        {
          customDomain,
          collectionId: await getCollectionId({ customDomain }),
          locale,
        }
      );
      if (!result.collection) throw new Error("No collection");
      const templateSettings = result.collection.site?.templateSettings ?? [];
      const projectId = templateSettings.find(
        ({ name }) => name === "projectId"
      )?.value;
      if (!projectId) throw new Error("No projectId");
      const sanityResult = await fetchSanity(
        allPageQuery,
        { collectionId: await getCollectionId({ customDomain }) },
        {
          projectId,
          ...getInvalidationPath({
            collectionId: await getCollectionId({ customDomain }),
            slug,
          }),
        }
      );
      const pageForSlug = sanityResult.data?.allPage?.find(
        (page) => page.slug?.current === slug
      );
      if (!pageForSlug) throw new Error("No page");
      return {
        ...pageForSlug,
        projectId,
      };
    },
  });
}

type LoaderData = Awaited<ReturnType<typeof Loader>>;
export async function Loader(args: LoaderFunctionArgs) {
  const params = paramsSchema.parse(args.params);
  const { locale, slug } = paramsSchema.parse(params);
  const customDomain = getCustomDomain(args);
  await queryClient.fetchQuery(getQuery({ locale, customDomain, slug }));
  return {
    customDomain,
  };
}

export default function LandingPageTemplate() {
  const params = useParams("/:locale/:slug");
  const { locale, slug } = paramsSchema.parse(params);
  const { customDomain } = useLoaderData() as LoaderData;
  const { data } = useQuery(getQuery({ locale, customDomain, slug }));
  if (!data) return null;
  return <SanityPage {...data} />;
}
