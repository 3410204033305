/** @jsxImportSource theme-ui */
import { Box } from "@bottlebooks/gatsby-theme-base/src";
import { FragmentType, graphql, useFragment } from "~/gql";
import { InterfaceLocale } from "~/gql/graphql";
import ClaimRepresentativeProfileButton from "./ClaimRepresentativeNotificationBar";
import Footer from "./Footer";
import FooterLogo from "./FooterLogo";
import Header from "./Header";
import MainMenu from "./MainMenu.next";

const fragment = graphql(/* GraphQL */ `
  fragment Layout on Collection {
    site {
      ...MainMenu
      landingPage {
        url
        type
        name
      }
    }
    # These should be in the Logo component fragment:
    logo {
      fixed(
        width: 60
        height: 40
        crop: PAD
        background: "#FFF0"
        quality: BEST
        gravity: WEST
      ) {
        src
      }
    }
    name
  }
`);

export default function Layout({
  children,
  data,
  locale,
  hideSearch = false,
  ...rest
}: {
  children: React.ReactNode;
  data: FragmentType<typeof fragment>;
  locale: InterfaceLocale;
  hideSearch?: boolean;
  [key: string]: any;
}) {
  const collection = useFragment(fragment, data);
  const landingPage = collection?.site?.landingPage?.url ?? "/";

  // Scrolling is currently disabled because it causes significant
  // peformance degredation on Safari. It should only be reactivated
  // once this issue has been resolved.
  // const { showHeader, isScrolledDown } = useScroll();
  const isScrolledDown = false;
  const showHeader = true;
  return (
    <Box sx={{ minHeight: "100vh" }}>
      <Header
        className="bb-header"
        homePath={landingPage}
        isScrolledDown={isScrolledDown}
        showHeader={showHeader}
        locale={locale}
        sx={{ width: "100%" }}
      >
        <MainMenu
          locale={locale}
          hideSearch={hideSearch}
          isScrolledDown={isScrolledDown}
          landingPage={landingPage}
          data={collection.site}
        />
      </Header>
      <ClaimRepresentativeProfileButton />

      <Box as="main">{children}</Box>

      <Footer sx={{ marginTop: "auto" }} homePath={landingPage}>
        {collection.logo?.fixed?.src ? (
          <FooterLogo to={landingPage} event={collection} />
        ) : null}
      </Footer>
    </Box>
  );
}

// function useMenuItems

// const query = graphql`
//   query LandingPageQuery {
//     event {
//       locale
//       landingPage
//     }
//   }
// `;
