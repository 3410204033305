import {
  multipleDetect,
  fromUrl,
  fromStorage,
  fromNavigator,
} from "@lingui/detect-locale";
import { Container, Stack, Text, Title } from "@mantine/core";
import { queryOptions } from "@tanstack/react-query";
import {
  isRouteErrorResponse,
  LoaderFunctionArgs,
  useRouteError,
} from "react-router-dom";
import { z } from "zod";
import { graphql } from "~/gql";
import graphQLClient from "~/graphQLClient";
import queryClient from "~/queryClient";
import { redirect } from "~/router";

const paramsSchema = z.object({
  collectionId: z.string().length(24),
  taskId: z.string().length(24),
  productId: z.string().length(24),
});

function query(params: {
  collectionId: string;
  taskId: string;
  productId: string;
}) {
  return queryOptions({
    queryKey: [
      "collection",
      params.collectionId,
      "tasks",
      params.taskId,
      "products",
      params.productId,
    ],
    throwOnError: true,
    queryFn: async () => {
      const result = await graphQLClient.request(
        graphql(`
          query ProductInfoPageRedirect($collectionId: ID!, $taskId: ID!) {
            collection(collectionId: $collectionId) {
              locales(format: RAW)
              collectionId
              task(taskId: $taskId) {
                template {
                  templateType
                }
                templateSettings {
                  name
                  value
                }
              }
            }
          }
        `),
        {
          collectionId: params.collectionId,
          taskId: params.taskId,
          productId: params.productId,
        }
      );
      if (!result.collection)
        throw new Error(
          `We couldn't find the collection ${params.collectionId}.`
        );
      if (!result.collection.task)
        throw new Error(
          `We couldn't find the task ${params.taskId} in collection ${params.collectionId}.`
        );
      const collection = result.collection;
      const task = collection.task;
      const taskLocale = task.templateSettings?.find(
        (setting) => setting.name === "locale"
      )?.value;
      return {
        locales: taskLocale ? [taskLocale] : collection.locales,
      };
    },
  });
}

export async function Loader(args: LoaderFunctionArgs) {
  const params = paramsSchema.parse(args.params);
  const { locales } = await queryClient.fetchQuery(query(params));
  const detectedLocales = multipleDetect(
    fromUrl("locale"),
    fromStorage("locale"),
    fromNavigator(),
    locales[0]
  );
  const locale = detectedLocales.find((locale) => locales.includes(locale)) ?? locales[0];
  throw redirect("/:locale/c/:collectionId/tasks/:taskId/products/:productId", {
    params: { ...params, locale },
  });
}

export default function ProductInfoPageRedirect() {
  return null;
}

export function Catch() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    console.log(error.data);
    return (
      <Container
        h="100vh"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack align="center" justify="center">
          <Title order={1} size="10rem" fw={900} c="gray.5">
            {error.status ?? "500"}
          </Title>
          <Text size="xs" ta="center" maw={500} mb="xl">
            {error.statusText ?? "No message was provided."}
          </Text>
        </Stack>
      </Container>
    );
  }
  if (error instanceof Error && error.stack) {
    console.log(error.stack);
  }
  if (isGraphQLError(error)) {
    return (
      <Container
        h="100vh"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack align="center" justify="center">
          <Title order={1} size="10rem" fw={900} c="gray.5">
            {error.response.status ?? "500"}
          </Title>
          {error.response.errors?.map((error, index) => (
            <Text key={index} size="xs" ta="center" maw={500} mb="xl">
              {error.message} Path: {error.path.join(".")}
            </Text>
          ))}
        </Stack>
      </Container>
    );
  }
  if (!(error instanceof Error)) {
    return (
      <Container
        h="100vh"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack align="center" justify="center">
          <Title order={1} size="10rem" fw={900} c="gray.5">
            {"500"}
          </Title>
          <Text size="xs" ta="center" maw={500} mb="xl">
            An unknown error occurred.
          </Text>
        </Stack>
      </Container>
    );
  }

  return (
    <Container
      h="100vh"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack align="center" justify="center">
        <Title order={1} size="10rem" fw={900} c="gray.5">
          {"status" in error &&
          (typeof error.status === "number" || typeof error.status === "string")
            ? error.status ?? "500"
            : "500"}
        </Title>
        <Text size="xs" ta="center" maw={500} mb="xl">
          {error.message ?? "No message was provided."}
        </Text>
      </Stack>
    </Container>
  );
}

type GraphQLErrorResponse = {
  response: {
    status: number;
    errors: Array<{
      message: string;
      path: Array<string>;
    }>;
  };
};

function isGraphQLError(error: unknown): error is GraphQLErrorResponse {
  return (
    error instanceof Object &&
    "response" in error &&
    error.response instanceof Object &&
    "status" in error.response &&
    "errors" in error.response
  );
}
