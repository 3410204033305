export default function toSanityImage({
  image,
  projectId,
  dataset = "production",
}: {
  image: ImageWithPreview | null;
  projectId: string;
  dataset?: "production" | "development";
}) {
  if (!image) return null;
  return {
    id: image.asset._id,
    projectId,
    dataset,
  };
}
