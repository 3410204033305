/** @jsxImportSource theme-ui */
import { FragmentType, graphql, useFragment } from "~/gql";
import ProductPriceExact from "./ProductPriceExact.next";
import ProductPriceRange from "./ProductPriceRange.next";
import getWfsPrice from "../../getWfsPrice";
import { Box, Flex, Text } from "@bottlebooks/gatsby-theme-base/src";

export const fragment = graphql(`
  fragment ProductPrice on RegisteredProduct {
    pricing {
      priceStrategy
      ...ProductPriceExact
      ...ProductPriceRange
      # This doesn't seem to be displayed anywhere...
      # bottleSize

      price
      priceRange
    }
    winesFromSpainAwardsCategory: customFieldValue(
      key: "winesFromSpainAwardsCategory"
    ) {
      __typename
      ... on TextFieldValue {
        value
      }
    }
    wfsPrice: customFieldValue(key: "wfsPrice") {
      __typename
      ... on TextFieldValue {
        value
      }
    }
    registration {
      exhibitorType: customFieldValue(
        key: "exhibitorType"
        _unstable_hidden: true
        experimentalKey: "jonathan@bottlebooks.me: @bottlebooks-site/packages/bottlebooks-site-base/src/components/ExhibitorsPage/exhibitorFilters.next.ts (wfsUk)"
      ) {
        __typename
        ... on SelectFieldValue {
          key
        }
      }
    }
  }
`);

export default function ProductPrice({
  pricing: data,
  prependPriceType = false,
  showMorePricingInfo = false,
  currencyDisplay,
  useGrouping,
  ...rest
}: {
  pricing: FragmentType<typeof fragment>;
  prependPriceType?: boolean;
  showMorePricingInfo?: boolean;
  currencyDisplay?: "symbol" | "code" | "name";
  useGrouping?: boolean;
  [x: string]: any;
}) {
  const registeredProduct = useFragment(fragment, data);
  const exhibitorType = registeredProduct.registration.exhibitorType?.key;
  const wfsPrice =
    registeredProduct.wfsPrice?.__typename === "TextFieldValue"
      ? registeredProduct.wfsPrice.value ?? ""
      : "";
  // if (!wfsPrice) return null;
  const pricing = getWfsPrice({
    exhibitorType,
    price: wfsPrice,
  })?.pricing;
  return (
    <>
      <ProductPriceExact
        pricing={pricing}
        prependPriceType={prependPriceType}
        currencyDisplay={currencyDisplay}
        useGrouping={useGrouping}
        {...rest}
      />
      {registeredProduct.winesFromSpainAwardsCategory?.value ? (
        <Flex align="flex-start" sx={{ marginLeft: -22, marginTop: 3 }} gap={1}>
          <img
            src="https://www.meininger.de/sites/meininger.de/files/tastings/charts/5539104/icex_gg.png"
            style={{
              width: 18,
              marginTop: 1,
              objectFit: "contain",
              objectPosition: "center center",
            }}
          ></img>

          <Box>
            <Text variant="smallest" sx={{ fontWeight: 600 }}>
              Wines from Spain Awards
            </Text>
            <Text variant="smallest" sx={{ fontWeight: 600 }}>
              Grand Gold
            </Text>
            <Text variant="smallest">
              {registeredProduct.winesFromSpainAwardsCategory?.value}
            </Text>
          </Box>
        </Flex>
      ) : null}
    </>
  );
}

ProductPrice.hasContent = hasContent;
function hasContent(data: FragmentType<typeof fragment>) {
  const registeredProduct = useFragment(fragment, data);
  const pricing = registeredProduct.pricing;
  if (pricing?.priceStrategy === "BY_PRICE" && pricing?.price != null)
    return true;
  if (
    pricing?.priceStrategy === "BY_PRICE_RANGE" &&
    pricing?.priceRange != null
  )
    return true;
  return false;
}
