/** @jsxImportSource theme-ui */
import { Box, Flex, Text } from "@bottlebooks/gatsby-design-system";
// import { graphql } from "gatsby";
import { gql } from "urql";
import Section from "./Layout/Section";
import PageReference from "./PageReference";
import CollectionFlightSection from "./Sections/CollectionFlightSection";
import CollectionFlightSectionFragment from "./Sections/CollectionFlightSectionFragment";
import ContactFormSection from "./Sections/ContactFormSection";
import FaqSection from "./Sections/FaqSection";
import IframeSection from "./Sections/IframeSection";
import ReferencedSection from "./Sections/ReferencedSection";
import RegistrationSection from "./Sections/RegistrationSection/RegistrationSection";
import SimpleGridSection from "./Sections/SimpleGridSection";
import SimpleGridSectionFragment from "./Sections/SimpleGridSectionFragment";
import BannerSection from "./Sections/Slider/BannerSection";
import Slider from "./Sections/Slider/Slider";
import SliderSectionFragment from "./Sections/Slider/SliderFragment";
import TextSection from "./Sections/TextSection";

export default function SanityPage({
  title,
  content,
  projectId,
  locale,
  slug,
}) {
  if (!content)
    return (
      <Flex align="center" justify="center" sx={{ height: 200 }}>
        <Text>Start designing this page by adding sections in the editor.</Text>
      </Flex>
    );
  return (
    <Box>
      {content?.map((data) => {
        const contentItem = afterQuery(data);
        const { style } = contentItem;
        switch (contentItem.__typename) {
          // Each case needs to have both
          case "FaqSection":
          case "SanityFaqSection": {
            return (
              <FaqSection key={contentItem._key} {...contentItem} sx={style} />
            );
          }

          case "BannerSection":
          case "SanityBannerSection": {
            return (
              <BannerSection
                key={contentItem._key}
                {...contentItem}
                projectId={projectId}
              />
            );
          }

          case "CollectionFlightSection":
          case "SanityCollectionFlightSection": {
            return (
              <CollectionFlightSection
                key={contentItem._key}
                {...contentItem}
              />
            );
          }

          case "SliderSection":
          case "SanitySliderSection": {
            return (
              <Slider
                key={contentItem._key}
                {...contentItem}
                style={{
                  ...contentItem.style,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
                projectId={projectId}
              />
            );
          }

          case "IframeSection":
          case "SanityIframeSection": {
            return <IframeSection key={contentItem._key} {...contentItem} />;
          }

          case "TextSection":
          case "SanityTextSection": {
            return <TextSection key={contentItem._key} {...contentItem} />;
          }

          case "ContactFormSection":
          case "SanityContactFormSection": {
            return (
              <ContactFormSection key={contentItem._key} {...contentItem} />
            );
          }

          case "ReferencedSection":
          case "SanityReferencedSection": {
            return (
              <ReferencedSection key={contentItem._key} {...contentItem} />
            );
          }

          case "SimpleGridSection":
          case "SanitySimpleGridSection": {
            return (
              <SimpleGridSection
                key={contentItem._key}
                {...contentItem}
                projectId={projectId}
              />
            );
          }

          case "RegistrationSection":
          case "SanityRegistrationSection": {
            return (
              <RegistrationSection key={contentItem._key} {...contentItem} />
            );
          }

          default: {
            return (
              <Section>
                <Box>Component not supported: {contentItem.__typename}</Box>
              </Section>
            );
          }
        }
      })}
    </Box>
  );
}

function afterQuery(contentItem) {
  const { style = {} } = contentItem;
  const { paddingTop = 4, paddingBottom = 4 } = style;
  // If 0 padding is selected in CMS, then we can't go lower than 0
  // TODO: we can't support responsive layout as we used to now that we use Mantine
  const responsivePaddingTop = paddingTop - 1 < 0 ? 0 : paddingTop - 1;
  const responsivePaddingBottom = paddingBottom - 1 < 0 ? 0 : paddingBottom - 1;
  return {
    ...contentItem,
    style: {
      ...style,
      // We're going to slightly reduce padding on smartphones automatically
      paddingTop: getMantineYPadding(paddingTop),

      paddingBottom: getMantineYPadding(paddingBottom),
    },
  };
}

export function getMantineYPadding(legacyPadding: number) {
  const mantinePadding = legacyStyles[legacyPadding];
  if (typeof mantinePadding === "undefined") return undefined;
  return `${mantinePadding}px`;
}
const legacyStyles: Record<string, number> = {
  0: 0,
  px: 1,
  0.5: 2,
  1: 4,
  1.5: 6,
  2: 8,
  2.5: 12,
  3: 16,
  3.5: 24,
  4: 32,
  4.5: 48,
  5: 64,
  5.5: 96,
  6: 128,
  6.5: 192,
  7: 256,
  7.5: 384,
  8: 512,
};

// export const fragment = graphql`
//   fragment SanityPage on SanityPage {
//     slug {
//       current
//     }
//     title
//     content {
//       __typename
//       ... on SanityBannerSection {
//         _key
//         ...SanityBannerSectionFragment
//       }
//       ... on SanityCollectionFlightSection {
//         _key
//         ...SanityCollectionFlightSectionFragment
//       }
//       ... on SanityTextSection {
//         _key
//         ...SanityTextSectionFragment
//       }
//       ... on SanityIframeSection {
//         _key
//         ...SanityIframeSectionFragment
//       }
//       ... on SanitySliderSection {
//         _key
//         ...SanitySliderSectionFragment
//       }
//       ... on SanityFaqSection {
//         _key
//         ...SanityFaqSectionFragment
//       }
//       ... on SanityContactFormSection {
//         _key
//         ...SanityContactSectionFragment
//       }
//       ... on SanityReferencedSection {
//         _key
//         ...SanityReferencedSectionFragment
//       }
//       ... on SanityRegistrationSection {
//         _key
//         ...SanityRegistrationFragment
//       }
//       ... on SanitySimpleGridSection {
//         _key
//         ...SanitySimpleGridSectionFragment
//       }
//     }
//   }

//   # Common elements

//   fragment BlockStyle on SanityBlockStyle {
//     gridColumns
//   }

//   fragment SectionStyle on SanitySectionStyle {
//     variant
//     alignment
//     paddingTop
//     paddingBottom
//   }
// `;

/**
 * This is for preview.
 *
 * Was not successful getting ${Component.fragment} pattern to work. Runtime complains of null.
 *
 */
SanityPage.fragment = gql`
  fragment SanityPage on Page {
    id: _id
    slug {
      current
    }
    title
    content {
      __typename
      ... on CollectionFlightSection {
        _key
        ...CollectionFlightSectionFragment
      }
      ... on TextSection {
        _key
        ...TextSectionFragment
      }
      ... on ContactFormSection {
        ...ContactFormSectionFragment
      }
      ... on IframeSection {
        _key
        ...IframeSectionFragment
      }
      ... on BannerSection {
        _key
        ...BannerSectionFragment
      }
      ... on SliderSection {
        _key
        ...SliderSectionFragment
      }
      ... on FaqSection {
        _key
        ...FaqSectionFragment
      }
      ... on ReferencedSection {
        _key
        ...ReferencedSectionFragment
      }
      ... on RegistrationSection {
        _key
        ...RegistrationSectionFragment
      }
      ... on SimpleGridSection {
        _key
        ...SimpleGridSectionFragment
      }
    }
  }

  fragment SectionStyle on SectionStyle {
    variant
    alignment
    paddingTop
    paddingBottom
  }

  fragment ImageWithPreview on Image {
    asset {
      _id
    }
    hotspot {
      height
      width
      x
      y
    }
    crop {
      bottom
      left
      right
      top
    }
  }

  # This is a mixure of fragment strategies.
  # Ultimately, all fragments should be extracted into named exports to avoid issues with circular dependencies.
  ${BannerSection.fragment}
  ${CollectionFlightSectionFragment}
  ${ContactFormSection.fragment}
  ${FaqSection.fragment}
  ${IframeSection.fragment}
  ${PageReference.fragment}
  ${ReferencedSection.fragment}
  ${RegistrationSection.fragment}
  ${SliderSectionFragment}
  ${SimpleGridSectionFragment}
  ${TextSection.fragment}
`;
