import { Container, Flex, View } from "@bottlebooks/gatsby-theme-base/src";

import React, { useEffect } from "react";

export default function Tickets() {
  return (
    <Flex direction="column" align="center">
      <Section sx={{ paddingTop: 2 }}>
        <UniverseTicketWidget />
      </Section>
    </Flex>
  );
}

function Section({ children, ...rest }: { children: React.ReactNode }) {
  return (
    <View
      sx={{
        paddingY: 4,
      }}
      {...rest}
    >
      <Container fluid sx={{ maxWidth: "container.fluid" }}>
        {children}
      </Container>
    </View>
  );
}

const UniverseTicketWidget = () => {
  useEffect(() => {
    const existingScript = document.getElementsByClassName("tmjs-iframe");
    if (existingScript.length > 0) {
      existingScript[0].parentElement?.removeChild(existingScript[0]);
    }
    // Create a script element
    const script = document.createElement("script");
    script.src = "https://www.universe.com/embed2.js";
    script.async = true;

    // Add custom data attributes
    script.setAttribute("data-widget-type", "universe-listings");
    script.setAttribute("data-target-type", "user");
    script.setAttribute("data-target-id", "66f55c8afbd97c0021ca53d3");
    script.setAttribute(
      "data-state",
      "buttonColor=#98002e&buttonText=Get Tickets"
    );
    const thiscomponent = document.getElementById("universe-ticket-widget");
    // Append the script to the document body
    thiscomponent?.appendChild(script);

    // Cleanup function to remove the script
    // return () => {
    //   document.body.removeChild(script);
    // };
  }, []);

  return <div id="universe-ticket-widget" style={{ width: 600 }}></div>;
};
