import { graphql } from "~/gql";
import graphQLClient from "~/graphQLClient";
import queryClient from "~/queryClient";

export default async function getCollectionId({
  customDomain,
}: {
  customDomain: string;
}) {
  const cachedResult = queryClient.getQueryData<
    { collectionId: string; customDomain: string } | undefined
  >(["collection", customDomain, "collectionId"]);
  if (cachedResult) return cachedResult.collectionId;

  /**
   * Resolve the collectionId for the given customDomain.
   */
  const result = await graphQLClient.request(
    graphql(`
      query ResolveCollectionId($customDomain: String!) {
        collection: _unstable_collectionByCustomDomain(
          experimentalKey: "jonathan@bottlebooks.me: @bottlebooks/bottlebooks-site/src/pages/[locale]/_layout.tsx"
          locale: en
          customDomain: $customDomain
        ) {
          collectionId
        }
      }
    `),
    { customDomain }
  );
  const collection = result.collection;
  if (!collection)
    throw new Error(`No collection found for this domain: ${customDomain}`);

  queryClient.setQueryData(["collection", customDomain, "collectionId"], {
    collectionId: collection.collectionId,
    customDomain,
  });

  return collection.collectionId;
}
