/** @jsxImportSource theme-ui */
import { Flex } from "@bottlebooks/gatsby-design-system";
import { FragmentType, graphql, useFragment } from "~/gql";
import ProductInfoSheetButton from "./ProductActions/ProductInfoSheetButton.next";
import ProductShopButton from "./ProductActions/ProductShopButton.next";

export const fragment = graphql(`
  fragment ProductActions_RegisteredProduct on RegisteredProduct {
    ...ProductInfoSheetButton
    ...ProductShopButton
  }
`);

export default function ProductActions({
  data,
  locale,
}: {
  data: FragmentType<typeof fragment>;
  locale: string;
}) {
  const registeredProduct = useFragment(fragment, data);
  return (
    <Flex
      direction={["column", "row"]}
      sx={{ gap: 2, alignItems: ["stretch", "center"] }}
    >
      <ProductShopButton data={registeredProduct} />
      <ProductInfoSheetButton locale={locale} data={registeredProduct} />
    </Flex>
  );
}
