/** @jsxImportSource theme-ui */
import DesktopLogo from "./DesktopLogo";

/**
 *
 * @param {object} props
 * @param {{ logo?: { fixed?: { src?: string | null | undefined } | null | undefined } | null | undefined, name: string } | null | undefined } props.event
 * @param {string } [props.to]
 * @param {boolean} [props.isScrolledDown]
 * @returns
 */
export default function Logo({ to, isScrolledDown, event, ...rest }) {
  return (
    <DesktopLogo
      to={to}
      isScrolledDown={isScrolledDown}
      event={event}
      width={60}
      height={40}
      {...rest}
    />
  );
}
