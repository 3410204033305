/** @jsxImportSource theme-ui */
import { Box } from "@bottlebooks/gatsby-theme-base/src";
import React from "react";
import { InterfaceLocale } from "~/gql/graphql";
import MainMenuDesktop from "./MainMenuDesktop.next";
import MainMenuMobile from "./MainMenuMobile";
import useMenuQuery from "./useMenuQuery";
import { FragmentType, graphql, useFragment } from "~/gql";

const fragment = graphql(`
  fragment MainMenu on SiteConfiguration {
    menuItems {
      type
      name
      url
    }
  }
`);
const MainMenu = React.memo(function MainMenu({
  data,
  isScrolledDown = false,
  hideSearch,
  landingPage,
  locale,
  ...rest
}: {
  data: FragmentType<typeof fragment> | null | undefined;
  isScrolledDown?: boolean;
  hideSearch?: boolean;
  landingPage: string;
  locale: InterfaceLocale;
  [key: string]: any;
}) {
  // TODO: Move CMS query to Bottlebooks resolvers?
  const cmsMenuItems = useMenuQuery({ locale });
  const siteConfiguration = useFragment(fragment, data);
  const menuItems = cmsMenuItems?.length
    ? cmsMenuItems
    : siteConfiguration?.menuItems;
  if (!menuItems?.length) return null;
  return (
    <Box sx={{ width: "100%" }}>
      <MainMenuDesktop
        sx={{ display: ["none", null, "inherit"] }}
        menuItems={menuItems}
        landingPage={landingPage}
        isScrolledDown={isScrolledDown}
        hideSearch={hideSearch}
        locale={locale}
        event={event}
        {...rest}
      />
      <MainMenuMobile
        sx={{ display: ["inherit", null, "none"] }}
        menuItems={menuItems}
        landingPage={landingPage}
        isScrolledDown={isScrolledDown}
        locale={locale}
        event={event}
        {...rest}
      />
    </Box>
  );
});
export default MainMenu;
