import { useCollectionLayout } from "@bottlebooks/bottlebooks-site-base/src/components/CollectionLayoutProvider.next";
import { FavoritesProvider } from "@bottlebooks/bottlebooks-site-bookmarks/src/useFavorite";
import { TastingNotesProvider } from "@bottlebooks/bottlebooks-site-tastingnotes/src/useTastingNote";
import Firebase from "@bottlebooks/gatsby-plugin-firebase-app/src/wrapRootElement";
import { AuthenticationProvider } from "@bottlebooks/gatsby-plugin-firebase-auth/src/useAuthentication";
import { UserProfileProvider } from "@bottlebooks/gatsby-plugin-firebase-auth/src/useUserProfile";

export default function FirebaseProviders({
  children,
}: {
  children: React.ReactNode;
}) {
  const { collectionId } = useCollectionLayout();
  if (!collectionId)
    throw new Error(
      "A CollectionLayoutProvider is necessary for the UserProfileProvider to work. Wrap the component in a <CollectionLayoutProvider> to fix this."
    );
  return (
    <Firebase>
      <AuthenticationProvider>
        <UserProfileProvider>
          <FavoritesProvider>
            <TastingNotesProvider>{children}</TastingNotesProvider>
          </FavoritesProvider>
        </UserProfileProvider>
      </AuthenticationProvider>
    </Firebase>
  );
}
