import ProducerPage from "@bottlebooks/bottlebooks-site-base/src/components/ProducerPage/ProducerPage.next";
import { LoaderFunctionArgs, useLoaderData, useParams } from "react-router-dom";
import { z } from "zod";
import { graphql } from "~/gql";
import { getFragment } from "~/gql-local/fragment-masking";
import graphQLClient from "~/graphQLClient";
import getCollectionId from "../../_getCollectionId";
import getCustomDomain from "../../_getCustomDomain";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
  producerId: z.string(),
  registrationId: z.string(),
});

const fragment = graphql(/* GraphQL */ `
  fragment RegisteredBrandPage_Event on Event {
    eventId
    registeredBrands(
      filter: {
        registrationId: { eq: $registrationId }
        brandId: { eq: $producerId }
      }
    ) {
      nodes {
        __typename
        # stand {
        #   name
        #   roomName
        # }
        # registrationId
        # regionSummary
        # priceSummary
        # priceRangeSummary
        # primaryVarietySummary
        registrationId

        companyId
        producerId: brandId
        products {
          nodes {
            productId
            exhibitorId: companyId
            producerId
            # ...ProducerPage_Product
          }
        }
        registration {
          companyId
          company {
            name
          }
        }
        ...RegisteredBrand_ProducerPage
        profile {
          ...BrandAddressSection_Profile
          ...BrandDetailsSection
          ...BrandLogo
          ...BrandHeader
          ...BrandBanner
          name
          sortName
          country
          countryName: country(format: LOCALIZED)
          countryCode: country(format: RAW)
          region
          city
          distributionInCountries
          seekingDistributionInCountries
        }
      }
    }
  }
`);

type LoaderData = Awaited<ReturnType<typeof Loader>>;
export async function Loader(args: LoaderFunctionArgs) {
  const { locale, producerId, registrationId } = paramsSchema.parse(
    args.params
  );
  const customDomain = getCustomDomain(args);
  const result = await graphQLClient.request(
    graphql(/* GraphQL */ `
      query RegisteredBrandPageByCustomDomain(
        $customDomain: String!
        $registrationId: ID!
        $producerId: ID!
        $locale: ContentLocale
      ) {
        collection: _unstable_collectionByCustomDomain(
          experimentalKey: "jonathan@bottlebooks.me: @bottlebooks/bottlebooks-site/src/pages/[locale]/_layout.tsx"
          customDomain: $customDomain
          locale: $locale
        ) {
          ...RegisteredBrandPage_Event
        }
      }
    `),
    {
      customDomain,
      registrationId,
      producerId,
      locale,
      // Required to access to cached data.
      collectionId: await getCollectionId({ customDomain }),
    },
    { "bottlebooks-use-request-cache": "true" }
  );
  return result.collection;
}

export default function ProducerPageTemplate() {
  const { locale } = paramsSchema.parse(useParams());
  const data = useLoaderData() as LoaderData;
  const event = getFragment(fragment, data);
  const registeredBrands = event?.registeredBrands.nodes;

  if (!registeredBrands) return <div>No registered brands found.</div>;
  const registeredBrand = registeredBrands[0];

  return (
    <ProducerPage
      key={`${registeredBrand.registrationId}-${registeredBrand.companyId}`}
      event={event}
      data={registeredBrand}
      locale={locale}
    />
  );
}
