import merge from "lodash/merge";
import { Helmet } from "react-helmet";
import { ThemeUIProvider } from "theme-ui";
import baseTheme from "../../../theme";

export default function BerkmannLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <ThemeUIProvider theme={useTheme()}>
      <Helmet>
        <style type="text/css">
          {`
            @font-face {
              font-family: 'Minion Pro';
              font-weight: 400;
              font-display: swap;
              font-style: normal;
              font-named-instance: 'Regular';
              src: url(//cdn.bottlebooks.me/58e231362373441932baed86/2025_01_16_4_11_30-MinionPro-Regular-1547.otf) format('woff2');
            }
            @font-face {
              font-family: 'Freight Sans';
              font-weight: 300;
              font-display: swap;
              font-style: normal;
              font-named-instance: 'Light';
              src: url(//cdn.bottlebooks.me/58e231362373441932baed86/2025_01_16_4_15_42-FreigSanLig-1562.otf) format('woff2');
            }
            @font-face {
              font-family: 'Freight Sans';
              font-weight: 600;
              font-display: swap;
              font-style: normal;
              font-named-instance: 'Medium';
              src: url(//cdn.bottlebooks.me/58e231362373441932baed86/2025_01_16_4_05_31-FreigSanMed-7663.otf) format('woff2');
            }
          `}
        </style>
      </Helmet>
      {children}
    </ThemeUIProvider>
  );
}

function useTheme() {
  return {
    ...baseTheme,
    colors: {
      ...baseTheme.colors,
      primary: "#002ea7",
      accent: "#002ea7",
    },
    fonts: merge({}, baseTheme.fonts, {
      heading: "Minion Pro, sans-serif",
      body: '"Freight Sans", serif',
    }),
    fontSizes: {
      ...baseTheme.fontSizes,
      largestTitle: 42, // 68 in base
      largeTitle: 32, // 42 in base
      title: 24, // 32 in base
      smallTitle: 20, // 24 in base
      smallestTitle: 18, // 20 in base
    },
    title: merge({}, baseTheme.title, {
      _base: {
        WebkitFontSmoothing: "antialiased",
        color: "primary",
      }, // Add antialiasing to titles.
      large: {
        ...baseTheme.title.large,
        letterSpacing: "1px",
        lineHeight: "1.3",
      },
      default: {
        ...baseTheme.title.default,
        letterSpacing: "1px",
        lineHeight: "1.3",
      },
      small: {
        ...baseTheme.title.small,
        letterSpacing: "1px",
        lineHeight: "1.3",
      },
      smallest: {
        ...baseTheme.title.smallest,
        letterSpacing: "1px",
        lineHeight: "1.3",
      },
    }),
    text: merge({}, baseTheme.text, {
      _base: {
        ...baseTheme.text._base,
      },
      large: {
        ...baseTheme.text.large,
        lineHeight: "23px",
        letterSpacing: "0.3px",
      },
      default: {
        ...baseTheme.text.default,
        letterSpacing: "0.3px",
      },
      small: {
        ...baseTheme.text.small,
        letterSpacing: "0.3px",
      },
      smallest: {
        ...baseTheme.text.smallest,
        letterSpacing: "0.3px",
      },

      semiTitle: {
        ...baseTheme.text.semiTitle,
        fontSize: "body",
      },
    }),

    styles: {
      ...baseTheme.styles,
      root: {
        ...baseTheme.styles.root,
        WebkitFontSmoothing: "subpixel-antialiased", // Garamond and DIN look bad with antialised in small sizes.
      },
    },
  };
}
