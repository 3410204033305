import ExhibitorsPage from "@bottlebooks/bottlebooks-site-base/src/components/ExhibitorsPage/ExhibitorsPage";
import { Card, Flex, Switch, Text } from "@mantine/core";
import deepMerge from "lodash/merge";
import { LoaderFunctionArgs, useLoaderData, useParams } from "react-router-dom";
import { z } from "zod";
import { bbCollectionIdSchema } from "~/bbCollectionIdSchema";
import { graphql } from "~/gql";
import { getFragment } from "~/gql-local/fragment-masking";
import graphQLClient from "~/graphQLClient";
import { useNavigate } from "~/router";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
  collectionId: z.string(),
});

const eventFragment = graphql(/* GraphQL */ `
  fragment ExhibitorsPage_Event on Event {
    registrations {
      nodes {
        __typename
        registrationId
        companyId
        ...ExhibitorDetail_ExhibitorsPage
      }
    }
    # TODO: do we really need this, or can it be consolidated to registrations?
    registeredBrands {
      nodes {
        partnerId
        ...exhibitorFilters_RegisteredBrand
        ...ExhibitorsPage_RegisteredBrand
      }
    }
  }
`);

const fragment = graphql(/* GraphQL */ `
  fragment ExhibitorDetail_ExhibitorsPage on SingleRegistration {
    __typename
    registrationId
    companyId
    ...exhibitorFilters
    ...ExhibitorsPage_Registration

    isHidden: customFieldValue(
      key: "isHidden"
      _unstable_hidden: true
      experimentalKey: "jonathan@bottlebooks.me: @bottlebooks-site/packages/bottlebooks-site/src/pages/[locale]/exhibitors/index.tsx"
    ) {
      ...FieldValue
    }
  }
`);

type LoaderData = Awaited<ReturnType<typeof Loader>>;
export async function Loader({ params }: LoaderFunctionArgs) {
  const { locale, collectionId: collectionIdSegment } =
    paramsSchema.parse(params);
  const collectionId = bbCollectionIdSchema.parse(collectionIdSegment);
  const { event } = await graphQLClient.request(
    graphql(/* GraphQL */ `
      query ExhibitorsPage($collectionId: ID!, $locale: ContentLocale) {
        event(eventId: $collectionId, locale: $locale) {
          eventId
          ...ExhibitorsPage_Event
        }
      }
    `),
    {
      collectionId,
      locale,
    },
    { "bottlebooks-use-request-cache": "true" }
  );
  return event;
}

export default function ExhibitorsTemplate() {
  const parsed = paramsSchema.parse(useParams());
  const data = useLoaderData() as LoaderData;
  const event = getFragment(eventFragment, data);

  const registrations = event?.registrations.nodes
    .flatMap((registration) => {
      const singleRegistration = getFragment(fragment, registration);
      if (singleRegistration.__typename !== "SingleRegistration") return null;
      const bb_registeredBrand = event.registeredBrands.nodes?.find(
        ({ partnerId }) => partnerId === singleRegistration.companyId
      );
      // The deepMerge logic below doesn't pay attention to this. RegisteredBrand.profile
      // is not the exhibitor profile.
      const safe_bb_registeredBrand = {
        ...bb_registeredBrand,
        profile: null,
      };

      return deepMerge(
        {},
        safe_bb_registeredBrand,

        singleRegistration,
        singleRegistration.profile,
        // {
        //   characteristics: getCharacteristicsForId({
        //     extractIdsFn: extractPartnerIdsForCharacteristics,
        //     id: exhibitor.exhibitorId,
        //     event: bb_event,
        //   }),
        // },
        {
          isHidden: singleRegistration?.isHidden?.value || false,
        }
      );

      // This is a bit messy. Would be nice to not have to spread.
      // return { ...singleRegistration, ...singleRegistration.profile };
    })
    .filter(Boolean);
  const visibleRegistrations = registrations?.filter(
    ({ isHidden }) => !isHidden
  );

  if (!visibleRegistrations) return <div>No registrations found.</div>;

  return (
    <>
      <FilterToggle />
      <ExhibitorsPage
        collectionId={parsed.collectionId}
        exhibitors={visibleRegistrations}
        locale={parsed.locale}
        // TODO: Not sure what this is
        location={"/"}
      />
    </>
  );
}

function FilterToggle() {
  const params = paramsSchema.parse(useParams());
  const navigate = useNavigate();
  if (!window.bbExhibitorFiltersPreview?.length) return;
  return (
    <Flex justify="center" bg="grey.0">
      <Card shadow="xs" padding="lg" radius="md" mt="md" mb="md" w="800">
        <Card.Section withBorder inheritPadding py="xs">
          <Flex justify="space-between" align="center">
            <Text fw={700} size="lg">
              Filter Configuration
            </Text>
            <Switch
              checked={true}
              onChange={() =>
                navigate(
                  "/:locale/collections/:collectionId/exhibitors/filters",
                  { params }
                )
              }
              label="Preview"
            />
          </Flex>
        </Card.Section>
      </Card>
    </Flex>
  );
}
