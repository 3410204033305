/** @jsxImportSource theme-ui */
import {
  Box,
  ChevronDownIcon,
  Flex,
  Label,
  Menu,
  MenuButton,
  MenuLink,
  MenuList,
  Text,
} from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import {
  useMatch,
  useNavigate as useReactRouterNavigate,
} from "react-router-dom";
import { FragmentType, graphql, useFragment } from "~/gql";

const fragment = graphql(/* GraphQL */ `
  fragment LanguageSwitcher on Collection {
    localeCodes: locales(format: RAW)
    localeNames: locales(format: LOCALIZED)
  }
`);

export default function LanguageSwitcher({
  data,
}: {
  data: FragmentType<typeof fragment>;
}) {
  const collection = useFragment(fragment, data);
  const navigate = useReactRouterNavigate();
  const match = useMatch("/:locale/*");
  if (!match) return null;
  if (!collection?.localeCodes?.length) return null;
  const selectedIndex = collection.localeCodes.findIndex(
    (localeCode) => localeCode === match.params.locale
  );
  return (
    <Menu>
      <MenuButton variant="text" sx={{ marginRight: 3 }}>
        <Flex align="center">
          <Box sx={{ textAlign: "left" }}>
            <Label
              variant="small"
              sx={{ color: "lighterText", lineHeight: "12px" }}
            >
              {/* TODO: Enable when we have a lingui set up in this repo. */}
              {/* <Trans>Language</Trans> */}
              Language
            </Label>
            {selectedIndex > -1 ? (
              <Text variant="small" sx={{ lineHeight: "16px" }}>
                {collection.localeNames[selectedIndex]}
              </Text>
            ) : null}
          </Box>
          <ChevronDownIcon
            sx={{ marginLeft: 1, color: "lighterText" }}
            size="small"
          />
        </Flex>
      </MenuButton>
      <MenuList>
        {collection.localeCodes.map((localeCode, index) => (
          <MenuLink
            key={localeCode}
            onSelect={() => navigate(`/${localeCode}/${match.params["*"]}`)}
            data-selected={selectedIndex === index}
          >
            {collection.localeNames[index]}
          </MenuLink>
        ))}
      </MenuList>
    </Menu>
  );
}
