import { useCollectionLayout } from "@bottlebooks/bottlebooks-site-base/src/components/CollectionLayoutProvider.next";
import { getAggregationList } from "@bottlebooks/bottlebooks-site-base/src/components/ExhibitorsPage/exhibitorFilters.next";
import useSiteConfig from "@bottlebooks/bottlebooks-site-base/src/components/useSiteConfig";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { useLingui } from "@lingui/react";
import {
  ActionIcon,
  Anchor,
  Button,
  Card,
  CopyButton,
  Flex,
  Group,
  Paper,
  Stack,
  Switch,
  Text,
  Tooltip,
} from "@mantine/core";
import {
  IconCheck,
  IconCopy,
  IconGripVertical,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "~/router";

export default function FilterConfig() {
  const params = useParams("/:locale/exhibitors");
  const navigate = useNavigate();
  const collectionLayout = useCollectionLayout();
  const { i18n } = useLingui();
  const { showDistributionDetails } = useSiteConfig();
  const allFacets = getAggregationList(showDistributionDetails);
  type Filter = (typeof allFacets)[number];
  const previewFacets = window.bbExhibitorFiltersPreview?.map((filter) => {
    const aggregation = allFacets.find((f) => f.key === filter);
    if (!aggregation) throw new Error(`Unknown filter: ${filter}`);
    return aggregation;
  });
  const currentFacets = collectionLayout.siteConfig.exhibitorFilters?.length
    ? collectionLayout.siteConfig.exhibitorFilters.map((filter) => {
        const aggregation = allFacets.find((f) => f.key === filter);
        if (!aggregation) throw new Error(`Unknown filter: ${filter}`);
        return aggregation;
      })
    : undefined;

  const [selectedFilters, setSelectedFilters] = useState<Filter[]>(
    previewFacets ?? currentFacets ?? allFacets
  );

  const addFilter = (filter: Filter) => {
    if (!selectedFilters.some((f) => f.key === filter.key)) {
      setSelectedFilters([...selectedFilters, filter]);
    }
  };

  const removeFilter = (key: string) => {
    setSelectedFilters(selectedFilters.filter((f) => f.key !== key));
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(selectedFilters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSelectedFilters(items);
  };

  const getConfiguration = () => {
    return selectedFilters.map((f) => f.key).join(",");
  };

  return (
    <Flex justify="center" mih="500" bg="grey.0">
      <Card shadow="xs" padding="lg" radius="md" mt="md" mb="md" w="800">
        <Card.Section withBorder inheritPadding py="xs">
          <Anchor component={Link} to="/:locale/bb-admin" params={params}>
            Admin dashboard
          </Anchor>
          <Flex justify="space-between" align="center">
            <Text fw={700} size="lg">
              Exhibitors Filter Configuration
            </Text>
            <Switch
              checked={false}
              onChange={() => {
                window.bbExhibitorFiltersPreview = selectedFilters.map(
                  (filter) => filter.key
                );
                return navigate("/:locale/exhibitors", {
                  params,
                });
              }}
              label="Preview"
            />
          </Flex>
        </Card.Section>

        <Stack mt="md" gap="md">
          <div>
            <Text fw={600} mb="xs">
              Available Filters
            </Text>
            <Group gap="xs">
              {allFacets.map((filter) => (
                <Button
                  key={filter.key}
                  variant="outline"
                  size="xs"
                  leftSection={<IconPlus size={14} />}
                  onClick={() => addFilter(filter)}
                  disabled={selectedFilters.some((f) => f.key === filter.key)}
                >
                  {i18n._(filter.title)}
                </Button>
              ))}
            </Group>
          </div>

          <div>
            <Text fw={600} mb="xs">
              Selected Filters
            </Text>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="filters">
                {(provided) => (
                  <Stack
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    gap="xs"
                  >
                    {selectedFilters.map((filter, index) => (
                      <Draggable
                        key={filter.key}
                        draggableId={filter.key}
                        index={index}
                      >
                        {(provided) => (
                          <Paper
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            shadow="xs"
                            p="sm"
                            withBorder
                          >
                            <Group justify="space-between">
                              <Group gap="sm">
                                <ActionIcon
                                  {...provided.dragHandleProps}
                                  variant="subtle"
                                  color="gray"
                                >
                                  <IconGripVertical size={18} />
                                </ActionIcon>
                                <Text>{i18n._(filter.title)}</Text>
                              </Group>
                              <ActionIcon
                                variant="subtle"
                                color="red"
                                onClick={() => removeFilter(filter.key)}
                              >
                                <IconTrash size={18} />
                              </ActionIcon>
                            </Group>
                          </Paper>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Stack>
                )}
              </Droppable>
            </DragDropContext>
          </div>

          <div>
            <Text fw={600}>Configuration</Text>
            <Text mb="xs">
              Copy and paste this into the variables for the website. The key
              should be "exhibitorFilters".
            </Text>
            <Paper p="sm" withBorder>
              <Group justify="space-between">
                <Text ff="monospace" fz="sm">
                  {getConfiguration()}
                </Text>

                <CopyButton value={getConfiguration()} timeout={2000}>
                  {({ copied, copy }) => (
                    <Tooltip
                      label={copied ? "Copied" : "Copy"}
                      withArrow
                      position="right"
                    >
                      <ActionIcon
                        color={copied ? "teal" : "gray"}
                        onClick={() => {
                          copy();
                          window.open(
                            `https://app.bottlebooks.me/selectBrand/${collectionLayout.workspaceId}?redirect_url=/dashboard/app/next/en/${collectionLayout.workspaceId}/c/${collectionLayout.collectionId}/en/settings/tasks/PRODUCT/${collectionLayout.siteTaskId}/edit`,
                            "_blank"
                          );
                        }}
                      >
                        {copied ? (
                          <IconCheck size={16} />
                        ) : (
                          <IconCopy size={16} />
                        )}
                      </ActionIcon>
                    </Tooltip>
                  )}
                </CopyButton>
              </Group>
            </Paper>
          </div>
        </Stack>
      </Card>
    </Flex>
  );
}
