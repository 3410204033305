export default function getWfsPrice({
  exhibitorType,
  price,
}: {
  exhibitorType: string;
  price: string;
}) {
  if (price === "0") return null;
  if (price === "") return null;
  const currencyCode = exhibitorType === "importer" ? "GBP" : "EUR";
  const priceType = exhibitorType === "importer" ? "RRP" : "EXW";
  return {
    pricing: {
      currencyCode,
      price: price?.replace(",", "."),
      priceStrategy: "BY_PRICE",
      priceType,
    },
  };
}
