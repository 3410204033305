import { load } from "@bottlebooks/bottlebooks-site-base/src/wrapElement/lingui/i18n.config";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { useEffect } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { z } from "zod";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
});

export default function LocaleLayout() {
  const { locale } = paramsSchema.parse(useParams());
  load(locale);
  i18n.activate(locale);
  // @ts-expect-error - not sure why we are setting this property
  i18n._locale = locale;
  return (
    <I18nProvider i18n={i18n}>
      <ScrollToTop />
      <Outlet />
    </I18nProvider>
  );
}

function ScrollToTop() {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}
