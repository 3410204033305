/** @jsxImportSource theme-ui */
import { Title } from "@bottlebooks/gatsby-design-system";
import { Box, Col, Container, Row } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import useLink from "../../useLink";
import BrandAddressSection from "../Brand/BrandAddressSection.next";
import BrandBanner from "../Brand/BrandBanner.next";
import BrandDetailsSection from "../Brand/BrandDetailsSection.next";
import BrandShopsSection from "../Brand/BrandShopsSection.next";
import ContentRow from "../ContentRow";
import Representatives from "../ExhibitorPage/Representatives.next";
import ProducerActions from "../Producer/ProducerActions";
import ProductCard from "../ProductCard/ProductCard.next";
import ProductListListRow from "../ProductListsList/ProductListListRow";
import ProductListsList from "../ProductListsList/ProductListsList";
import ProductsList from "../ProductsList/ProductsList";
import ProducerHeaderRow from "./ProducerHeaderRow.next";

export const fragment = graphql(/* GraphQL */ `
  fragment RegisteredBrand_ProducerPage on RegisteredBrand {
    collectionId
    ...RegisteredBrand_ProducerHeaderRow
    ...ProducerFilters_RegisteredBrand
    ...BrandDetailsSection_PresentedBrand
    profile {
      ...BrandDetailsSection
    }
    products {
      nodes {
        ...ProductCard_RegisteredProduct
        productId
        ...ProductListRow
      }
    }
    registration {
      ...Representatives
      ...ExhibitorSections
      ...Registration_ProducerHeaderRow
      profile {
        ...BrandDetailsSection
        ...BrandAddressSection_Profile
        ...BrandShopsSection
      }
    }
  }
`);

export default function ProducerPage({
  // productLists,
  previous,
  next,
  // locale,
  event,
  data,
}: {
  event: unknown;
  data: FragmentType<typeof fragment>;
  // productLists: unknown[];
  previous: string;
  next: string;
  // locale: string;
}) {
  const registeredBrand = useFragment(fragment, data);
  const products = registeredBrand?.products?.nodes;
  const link = useLink();
  const hasProducts = products && products.length !== 0;
  const hasProductLists = false; // productLists && productLists.length !== 0;
  const hasProductsSection = hasProducts || hasProductLists;
  const producer = { ...registeredBrand, ...registeredBrand.profile };
  const exhibitor = {
    ...registeredBrand.registration,
    ...registeredBrand.registration.profile,
  };
  const withRegisteredBrand = products.map((product) => ({
    ...product,
    producer: {
      ...product.producer,
      ...registeredBrand.profile,
    },
  }));
  return (
    <Box>
      <BrandBanner data={producer} />
      <ProducerHeaderRow
        exhibitor={registeredBrand.registration.profile}
        producer={producer}
      />

      <Container>
        <Row
          sx={{
            paddingY: 3,
            paddingX: [null, `${100 / 12}%`], // 1 col spacing from medium width up.
          }}
        >
          <Col>
            <ProducerActions producer={producer} />
          </Col>
        </Row>
        {/* {producer.registrationCount === 1 && ( */}
        {/* TODO: this will need to be resolved when multiple registrations show
        products from the same producer. Or, we get rid of producer pages altogether. */}
        <ContentRow>
          <Col flex>
            <Representatives
              data={registeredBrand.registration}
              sx={{ flex: "1 0 auto" }}
            />
          </Col>
        </ContentRow>
        {/* )} */}
        <Row
          sx={{
            paddingX: [null, `${100 / 12}%`], // 1 col spacing from medium width up.
          }}
        >
          <BrandDetailsSection
            data={registeredBrand.profile}
            presentedBrandsData={[registeredBrand]}
          />
        </Row>
      </Container>

      <BrandAddressSection data={producer} />
      <Container
        sx={{
          paddingX: [null, `${100 / 12}%`], // 1 col spacing from medium width up.
        }}
      >
        <BrandShopsSection data={producer} />
      </Container>

      {hasProductsSection ? (
        <Container fluid sx={{ maxWidth: "container.fluid" }}>
          <Title sx={{ marginBottom: 4 }}>
            <Trans>Products</Trans>
          </Title>
          {hasProductLists && (
            <ProductListsList sx={{ marginX: [-3, -2], marginY: -2 }}>
              {productLists.map((productList) => (
                <ProductListListRow
                  key={productList.id}
                  productList={productList}
                  to={link.productList(productList)}
                  sx={{ marginX: [0, 2], marginY: [0, 2] }}
                />
              ))}
            </ProductListsList>
          )}

          {hasProducts && (
            <ProductsList
              count={products.length}
              sx={{ marginX: [-3, -2], marginY: -2 }}
            >
              {withRegisteredBrand.map((registeredProduct) => (
                <ProductCard
                  key={registeredProduct.productId}
                  product={registeredProduct}
                  sx={{ marginBottom: [null, null, 3] }}
                />
              ))}
            </ProductsList>
          )}
        </Container>
      ) : null}
    </Box>
  );
}
