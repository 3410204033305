import type {
  Aggregation,
  FilterDefinitions,
  Sorting,
} from "@bottlebooks/bottlebooks-site-base/src/components/Filters/useIndex";
import { defineMessage } from "@lingui/macro";
import { graphql } from "~/gql";
import { ProductFiltersFragment } from "~/gql/graphql";
import { useCollectionLayout } from "../CollectionLayoutProvider.next";

export const fragment = graphql(/* GraphQL */ `
  fragment productFilters on RegisteredProduct {
    customerSegments: customFieldValue(key: "customerSegment") {
      ...FieldValue
    }
    currentlyAvailableInUkRetail: customFieldValue(
      key: "currentlyAvailableInUkRetail"
    ) {
      ...FieldValue
    }
    highlights: customFieldValue(key: "highlights") {
      ...FieldValue
    }
    thisIsANewProductForTheUkIn2025: customFieldValue(
      key: "thisIsANewProductForTheUkIn2025"
    ) {
      ...FieldValue
    }

    # Required when sorting products by exhibitor (which we don't do at the moment)
    registration {
      registrationId
      profile {
        sortName
        name
      }
      stand {
        floorName
        roomName
      }
      exhibitorType: customFieldValue(
        key: "exhibitorType"
        _unstable_hidden: true
        experimentalKey: "jonathan@bottlebooks.me: @bottlebooks-site/packages/bottlebooks-site-base/src/components/ExhibitorsPage/exhibitorFilters.next.ts (wfsUk)"
      ) {
        __typename
        ... on SelectFieldValue {
          key
        }
      }
      ...ProductStand
    }
    # Required for sorting
    sortIndex
    flightName
    productId
    exhibitorId: companyId
    pricing {
      priceType
      price
      currencyCode: currency(format: RAW)
      priceStrategy
      priceRange
    }
    highlights: customFieldValue(key: "highlights") {
      ...FieldValue
    }
    product {
      __typename
      name
      featuredContentTypes
      shortName
      # For rendering the product details
      countryName: country(format: LOCALIZED)
      countryCode: country(format: RAW)
      producer {
        producerId
        sortName
        name
        # For rendering the producer header
        countryName: country(format: LOCALIZED)
        countryCode: country(format: RAW)
        city
      }
      productType
      bottleImage {
        publicId
      }
      regionHierarchy
      bottleVariants {
        closureType
      }
      ... on Wine {
        wineType
        characteristics
        classification
        designation
        specialClassification
        vintage(removeNonVintage: true)
        denomination
        grapeVarieties {
          varietyName
        }
      }
      # ... on Beer {
      #   beerType
      # }
      ... on Spirit {
        spiritType
      }
    }
  }
`);

// #region Aggregations

// Create a function to provide type safety to the individual aggregation definitions
function asAggregation(aggregation: Aggregation<ProductFiltersFragment>) {
  return aggregation;
}
const aggregations = {
  flight: asAggregation({
    title: defineMessage({ message: "Flights" }),
    conjunction: false,
    multiSelect: false,
    size: 100,
    sort: "term",
    get: (product: ProductFiltersFragment) => product.flightName || [], // Excludes nulls
  }),
  denomination: {
    title: defineMessage({ message: "D.O." }),
    conjunction: false,
    multiSelect: false,
    size: 1000,
    sort: "term",
    get: (registeredProduct: ProductFiltersFragment) => {
      const product = registeredProduct.product;
      const wine = product.__typename === "Wine" ? product : null;
      if (!wine?.denomination) return undefined; // Excludes nulls
      return wine.denomination || undefined;
    },
  },
  primaryGrapeVariety: asAggregation({
    title: defineMessage({ message: "Primary grape varieties" }),
    conjunction: false,
    size: 300,
    get: (registeredProduct) => {
      const product = registeredProduct.product;
      const wine = product.__typename === "Wine" ? product : null;
      const grapeVarieties = wine?.grapeVarieties;
      if (!grapeVarieties?.length) return [];
      const varieties = grapeVarieties
        ?.map(({ varietyName }) => varietyName)
        .filter(Boolean);
      return varieties[0];
    },
  }),
  highlights: asAggregation({
    title: defineMessage({ message: "Highlights" }),
    conjunction: false,
    get: (registeredProduct) => {
      const thisIsANewProductForTheUkIn2025 =
        registeredProduct.thisIsANewProductForTheUkIn2025?.value;
      const isWineFromSpainAwards =
        registeredProduct.registration.exhibitorType?.key === "wfsAwards"
          ? "Wines from Spain Awards"
          : null;
      const highlights =
        registeredProduct.highlights?.values?.map(
          (highlight) => highlight || ""
        ) || [];
      const withNewFor2025 = [
        ...highlights,
        isWineFromSpainAwards,
        thisIsANewProductForTheUkIn2025 ? "New for 2025" : null,
      ].filter(Boolean);
      return withNewFor2025.filter((highlight) => highlight !== "None");
    },
    size: 20,
  }),
  productType: asAggregation({
    title: defineMessage({ message: "Product types" }),
    conjunction: false,
    get: (registeredProduct) => {
      const product = registeredProduct.product;
      const wine = product.__typename === "Wine" ? product : null;
      const value =
        wine?.wineType ||
        // product.spiritType ||
        // product.beerType ||
        product.productType;
      if (!value) return [];
      return [value];
    },
    size: 20,
    translations: {
      WINE: defineMessage({ message: "Other wine" }),
      SPIRIT: defineMessage({ message: "Spirit" }),
      Stillwein: defineMessage({ message: "Wine" }),
      BEER: defineMessage({ message: "Beer and more" }),
    },
  }),
  availableInUKRetail: asAggregation({
    title: defineMessage({ message: "Available in UK Retail" }),
    conjunction: false,
    get: (registeredProduct) => {
      return registeredProduct.currentlyAvailableInUkRetail?.value
        ? "Available in UK Retail"
        : undefined;
    },
    size: 20,
  }),
  customerSegments: asAggregation({
    title: defineMessage({ message: "Customer segments" }),
    conjunction: false,
    get: (registeredProduct) => {
      const customerSegments =
        registeredProduct.customerSegments?.values?.map(
          (segment) => segment || ""
        ) || [];

      return [...customerSegments].filter(Boolean);
    },
    size: 20,
  }),
  vintage: asAggregation({
    title: defineMessage({ message: "Vintages" }),
    conjunction: false,
    size: 50,
    sort: "term",
    order: "desc",
    get: (registeredProduct) => {
      const product = registeredProduct.product;
      const wine = product.__typename === "Wine" ? product : null;
      if (!wine?.vintage) return [];
      return [wine.vintage];
    },
    translations: {
      NV: defineMessage({ message: "NV" }),
    },
  }),
} as const;

type AggregationKey = keyof typeof aggregations;
// We have to force the key type. Otherwise, the type of the aggregation is inferred as
// the type of the key, which is a string.
const aggregationKeys = Object.keys(aggregations) as AggregationKey[];
export const aggregationList = aggregationKeys.map((key) => ({
  ...aggregations[key],
  key,
}));
// #endregion

// #region Sortings
const sortings: Record<string, Sorting<ProductFiltersFragment>> = {
  byProductName: {
    title: defineMessage({ message: "Product name" }),
    field: ["shortName", "vintage"],
    order: ["asc", "desc"],
  },
  bySortIndex: {
    title: defineMessage({ message: "Sort number" }),
    field: ["sortIndex"],
    group: "flightName",
    groupHeader: "flightName",
    order: ["asc"],
  },
  // byExhibitorName: {
  //   title: defineMessage({ message: 'Exhibitor name' }),
  //   field: [
  //     'exhibitor.sortName',
  //     'exhibitor.name',
  //     'producer.sortName',
  //     'producer.name',
  //   ],
  //   order: 'asc',
  //   group: 'exhibitorId',
  // },
  // byStandNumber: {
  //   title: defineMessage({ message: 'Table number' }),
  //   field: [
  //     sortBy.standName,
  //     'exhibitor.sortName',
  //     'exhibitor.name',
  //     'producer.sortName',
  //     'producer.name',
  //   ],
  //   order: 'asc',
  //   group: 'registration.registrationId',
  // },
};
// #endregion

const baseConfiguration = {
  aggregations,
  sortings,
};

// #region useProductFilters
export default function useProductFilters(): FilterDefinitions<ProductFiltersFragment> {
  const collection = useCollectionLayout();
  const selectedAggregations =
    window.bbProductFiltersPreview || collection.siteConfig.productFilters;
  if (!selectedAggregations?.length) return baseConfiguration;
  const pickedAggregations = selectedAggregations.reduce<
    Record<string, Aggregation<ProductFiltersFragment>>
  >((acc, aggregationKey) => {
    // @ts-expect-error - We need to coerse type from string to key
    const aggregation = baseConfiguration.aggregations[aggregationKey];
    // This shouldn't happen
    if (!aggregation) return acc;
    acc[aggregationKey] = aggregation;
    return acc;
  }, {});

  return { ...baseConfiguration, aggregations: pickedAggregations };
}
// #endregion

// Sort helper functions
const sortBy = {
  standName: (product) =>
    product?.stand?.name
      .split(/[\s,-]+/)
      .map((segment) =>
        segment === "Tisch"
          ? undefined
          : Number(segment)
          ? segment.padStart(3, "0")
          : segment
      )
      .filter(Boolean)
      .join(" ") || "999",
};
