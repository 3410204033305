/** @jsxImportSource theme-ui */
import { Button, DownloadIcon } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { z } from "zod";
import { FragmentType, graphql, useFragment } from "~/gql";

const fragment = graphql(`
  fragment ProductInfoSheetButton on RegisteredProduct {
    collectionId
    collection {
      site {
        templateSettings {
          name
          value
        }
      }
    }
    companyId
    product {
      name
    }
    productId
  }
`);

const siteConfigSchema = z.object({
  productInfoSheetTemplateId: z
    .string()
    .optional()
    .default("Bottlebooks/InfoSheetWithQRCodes"),
});

export default function ProductInfoSheetButton({
  data,
  locale,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
  locale: string;
}) {
  const registeredProduct = useFragment(fragment, data);
  const siteConfig = siteConfigSchema.parse(
    Object.fromEntries(
      (registeredProduct.collection?.site?.templateSettings ?? []).map(
        ({ name, value }) => [name, value]
      )
    )
  );
  return (
    <Button
      href={`https://app.bottlebooks.me/pdf/rp/${
        registeredProduct.collectionId
      }/${registeredProduct.companyId}/${
        registeredProduct.productId
      }/t/${encodeURIComponent(
        siteConfig.productInfoSheetTemplateId
      )}/f/${encodeURIComponent(
        registeredProduct.product.name ?? ""
      )}?inline=1&locale=${locale}`}
      target="_blank"
      rel="noopener noreferrer"
      variant="outline"
      sx={{ textAlign: "center" }}
      {...rest}
    >
      <DownloadIcon
        size="small"
        sx={{ marginRight: 2, color: "currentColor" }}
      />
      <Trans>Download info sheet</Trans>
    </Button>
  );
}
