import {
  fromNavigator,
  fromStorage,
  fromUrl,
  multipleDetect,
} from "@lingui/detect-locale";
import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { graphql } from "~/gql";
import graphQLClient from "~/graphQLClient";
import getCustomDomain from "./[locale]/_getCustomDomain";

export async function Loader(args: LoaderFunctionArgs) {
  const customDomain = getCustomDomain(args);
  try {
    const result = await graphQLClient.request(
      graphql(`
        query RedirectToDefaultLocale($customDomain: String!) {
          collection: _unstable_collectionByCustomDomain(
            experimentalKey: "jonathan@bottlebooks.me: @bottlebooks/bottlebooks-site/src/pages/[locale]/_layout.tsx"
            locale: en
            customDomain: $customDomain
          ) {
            collectionId
            locales(format: RAW)
          }
        }
      `),
      { customDomain }
    );
    const collection = result.collection;
    if (!collection)
      return redirect(
        `/error?errorCode=404&message=No collection found for this domain: ${customDomain}`
      );
    if (!collection.locales?.length)
      return redirect(
        `/error?errorCode=500&?message=No locales found for this collection: ${collection.collectionId}`
      );
    const locales = result.collection?.locales ?? [];

    const detectedLocales = multipleDetect(
      fromUrl("locale"),
      fromStorage("locale"),
      fromNavigator(),
      locales[0]
    );
    const locale =
      detectedLocales.find((locale) => locales.includes(locale)) ?? locales[0];
    return redirect(`/${locale}/${args.params["*"]}`);
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : "Unknown error";
    return redirect(
      `/error?errorCode=500&message=Error loading collection: ${errorMessage}`
    );
  }
}
