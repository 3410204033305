import merge from "lodash/merge";
import { darken } from "polished";
import { Helmet } from "react-helmet";
import { ThemeUIProvider } from "theme-ui";
import baseTheme from "../../../theme";

export default function CaliforniaLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <ThemeUIProvider theme={useTheme()}>
      <Helmet>
        <link
          rel="stylesheet"
          href="//fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap"
        ></link>
        <style type="text/css">
          {`
              // Mantine defaults to antialiased font smoothing, which looks
              // terrible on the Top Drop site
              :root {
                --mantine-webkit-font-smoothing: subpixel-antialiased;
              }
              html, body {
                -webkit-font-smoothing: subpixel-antialiased;
              }
              @font-face {
                font-family: RockfordSans;
                font-weight: 700;
                src: url(//discovercaliforniawines.com/wp-content/themes/dcw/fonts/RockfordSans/normal_normal_bold.woff2) format("woff");
              }
              @font-face {
                font-family: RockfordSans;
                font-weight: 400;
                src: url(//discovercaliforniawines.com/wp-content/themes/dcw/fonts/RockfordSans/normal_normal_normal.woff2) format("woff");
              }
          `}
        </style>
      </Helmet>
      {children}
    </ThemeUIProvider>
  );
}

function useTheme() {
  return {
    ...baseTheme,
  
    fonts: merge({}, baseTheme.fonts, {
      heading: 'RockfordSans, sans-serif',
      body: 'RockfordSans, sans-serif',
    }),
    fontWeights: {
      ...baseTheme.fontWeights,
      heading: '700',
      body: '400',
    },
    title: merge({}, baseTheme.title, {
      _base: {
        WebkitFontSmoothing: 'antialiased',
      }, // Add antialiasing to titles.
    }),
    colors: merge({}, baseTheme.colors, {
      ...baseTheme.colors,
      primary: '#21699F',
      secondary: darken(0.1)('#21699F'),
      accent: '#ffbd26',
    }),
    label: {
      ...baseTheme.label,
      _base: { ...baseTheme.label._base, color: '#666' },
    },
    text: {
      ...baseTheme.text,
     
    },
    styles: {
      ...baseTheme.styles,
      root: {
        ...baseTheme.styles.root,
        WebkitFontSmoothing: 'subpixel-antialiased',
      },
    },
  };
}
