import ProductsPage from "@bottlebooks/bottlebooks-site-base/src/components/ProductsPage/ProductsPage.next";
import { Card, Flex, Switch, Text } from "@mantine/core";
import { LoaderFunctionArgs, useLoaderData, useParams } from "react-router-dom";
import { z } from "zod";
import { bbCollectionIdSchema } from "~/bbCollectionIdSchema";
import { graphql } from "~/gql";
import { getFragment } from "~/gql-local/fragment-masking";
import graphQLClient from "~/graphQLClient";
import { useNavigate } from "~/router";
import getCollectionId from "../_getCollectionId";
import getCustomDomain from "../_getCustomDomain";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
});

const fragment = graphql(/* GraphQL */ `
  fragment ProductsPage on Event {
    eventId
    isProductSortingEnabled
    ...ProductsPageBase_Collection
    registeredProducts {
      nodes {
        ...ProductsPageBase
        productId
        # exhibitorId
        producerId
        registration {
          stand {
            name
          }
          profile {
            name
          }
        }
        product {
          shortName
          bottleImage {
            publicId
          }
          producer {
            name
            logo {
              publicId
            }
          }
        }
      }
    }
    registeredProducts {
      nodes {
        ...productFilters
      }
    }
  }
`);

type LoaderData = Awaited<ReturnType<typeof Loader>>;
export async function Loader(args: LoaderFunctionArgs) {
  const { locale } = paramsSchema.parse(args.params);
  const customDomain = getCustomDomain(args);
  const data = await graphQLClient.request(
    graphql(/* GraphQL */ `
      query ProductsPageByCustomDomain(
        $customDomain: String!
        $locale: ContentLocale
      ) {
        event: _unstable_collectionByCustomDomain(
          experimentalKey: "jonathan@bottlebooks.me: @bottlebooks/bottlebooks-site/src/pages/[locale]/_layout.tsx"
          customDomain: $customDomain
          locale: $locale
        ) {
          collectionId
          ...ProductsPage
        }
      }
    `),
    {
      customDomain,
      locale,
      // Required to access to cached data.
      collectionId: await getCollectionId({ customDomain }),
    },
    { "bottlebooks-use-request-cache": "true" }
  );
  return data.event;
}

export default function ProductPageTemplate() {
  const parsed = paramsSchema.parse(useParams());
  const data = useLoaderData() as LoaderData;
  const registeredProducts = getFragment(fragment, data);
  // const event = getFragment(eventConfigurationFragment, cards);
  const products = registeredProducts?.registeredProducts?.nodes.map(
    (registeredProduct) => ({
      ...registeredProduct,
      ...registeredProduct.product,
      exhibitor: registeredProduct.registration.profile,
    })
  );
  if (!registeredProducts) return <div>No product found.</div>;
  return (
    <>
      <FilterToggle />
      <ProductsPage
        event={data}
        collectionId={data?.collectionId}
        products={products}
        locale={parsed.locale}
        // TODO: Not sure what this is
        location={"/"}
      />
    </>
  );
}

function FilterToggle() {
  const params = paramsSchema.parse(useParams());
  const navigate = useNavigate();
  if (!window.bbProductFiltersPreview?.length) return;
  return (
    <Flex justify="center" bg="grey.0">
      <Card shadow="xs" padding="lg" radius="md" mt="md" mb="md" w="800">
        <Card.Section withBorder inheritPadding py="xs">
          <Flex justify="space-between" align="center">
            <Text fw={700} size="lg">
              Filter Configuration
            </Text>
            <Switch
              checked={true}
              onChange={() => navigate("/:locale/products/filters", { params })}
              label="Preview"
            />
          </Flex>
        </Card.Section>
      </Card>
    </Flex>
  );
}
