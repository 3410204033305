/** @jsxImportSource theme-ui */
import { Flex } from "@bottlebooks/gatsby-theme-base/src";
import gql from "graphql-tag";
import useLink from "../../useLink";
import ChangeProductLink from "./ChangeProductLink";

export default function ProductNavigation({ previous, next }) {
  const link = useLink();
  return (
    <Flex
      sx={{
        position: "absolute",
        top: [500, null, 200],
        left: [0, null, -20],
        right: [0, null, -20],
        justifyContent: "space-between",
      }}
    >
      <ChangeProductLink
        product={previous}
        to={previous && link.product(previous)}
        direction="previous"
      />

      <ChangeProductLink product={next} to={next && link.product(next)} />
    </Flex>
  );
}

ProductNavigation.fragment = gql`
  fragment ProductNavigation on Product {
    ...useLink_Product
  }
  ${useLink.productFragment}
`;
