// This must be client-side because of the current version of emotion
import baseTheme from "@bottlebooks/bottlebooks-site-base/src/theme";
import sharedTheme from "@bottlebooks/gatsby-shared/src/theme";
import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import { MantineEmotionProvider, emotionTransform } from "@mantine/emotion";
import { ThemeUIProvider } from "theme-ui";
import { theme as mantineTheme } from "~/mantineTheme";

export default function LayoutProviders({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <ThemeUIProvider theme={{ ...baseTheme, ...sharedTheme }}>
      <MantineProvider theme={mantineTheme} stylesTransform={emotionTransform}>
        <MantineEmotionProvider>{children}</MantineEmotionProvider>
      </MantineProvider>
    </ThemeUIProvider>
  );
}
