import gql from "graphql-tag";
import React from "react";
import type { LinkContext } from "./getLink";
import getLink from "./getLink";

const LinkContext = React.createContext<LinkContext | undefined>(undefined);

export function LinkProvider({
  value,
  children,
}: {
  value:
    | LinkContext
    | ((context: LinkContext | undefined) => LinkContext)
    | undefined;
  children: React.ReactNode;
}) {
  const context = React.useContext(LinkContext);
  if (typeof value === "function") {
    return (
      <LinkContext.Provider value={value(context)}>
        {children}
      </LinkContext.Provider>
    );
  }
  if (context) {
    return (
      <LinkContext.Provider value={{ ...context, ...value }}>
        {children}
      </LinkContext.Provider>
    );
  }

  return <LinkContext.Provider value={value}>{children}</LinkContext.Provider>;
}

export default function useLink() {
  const context = React.useContext(LinkContext);
  return getLink(context);
}

useLink.productFragment = gql`
  fragment useLink_Product on Product {
    ... on ExtendedProduct {
      exhibitorId
    }
    productId
  }
`;

useLink.registeredProductFragment = gql`
  fragment useLink_RegisteredProduct on Product {
    exhibitorId
    productId
  }
`;
useLink.producerFragment = gql`
  fragment useLink_Producer on Producer {
    producerId
  }
`;

useLink.exhibitorFragment = gql`
  fragment useLink_Exhibitor on Exhibitor {
    exhibitorId
  }
`;
