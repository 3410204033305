import { useCollectionLayout } from "@bottlebooks/bottlebooks-site-base/src/components/CollectionLayoutProvider.next";
import TastingNotesPage from "@bottlebooks/bottlebooks-site-tastingnotes/src/components/TastingNotesPage";
import { z } from "zod";
import { useParams } from "~/router";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
});

export default function ProfileRoute({ ...rest }: {}) {
  const params = useParams("/:locale/my/tasting-notes");
  const { locale } = paramsSchema.parse(params);
  const { collectionId } = useCollectionLayout();
  return (
    <TastingNotesPage locale={locale} collectionId={collectionId} {...rest} />
  );
}
