import useLink from "@bottlebooks/bottlebooks-site-base/src/useLink";

export default function getUrlFromPageReference(pageReference: {
  __typename:
    | "Page"
    | "SanityPage"
    | "ExternalPage"
    | "SanityExternalPage"
    | "BottlebooksPage"
    | "SanityBottlebooksPage";
  slug?: { current: string };
  url?: string;
  type: string;
}) {
  const base = useLink().base;
  if (!pageReference) return null;
  switch (pageReference.__typename) {
    case "Page":
    case "SanityPage":
      return `${base}/${pageReference?.slug?.current}`;
    case "ExternalPage":
    case "SanityExternalPage":
      return pageReference.url;
    case "BottlebooksPage":
    case "SanityBottlebooksPage":
      // Not sure why context isn't available
      // return link[pageReference.type]?.();
      return `${base}/${pageReference.type}`;
    default:
      return null;
  }
}
