/** @jsxImportSource theme-ui */
import { Text } from "@bottlebooks/gatsby-design-system";
import {
  FacebookIcon,
  InstagramIcon,
  Link,
  TwitterIcon,
  YoutubeIcon,
} from "@bottlebooks/gatsby-theme-base/src";
import { FragmentType, graphql, useFragment } from "~/gql";

const fragment = graphql(`
  fragment BrandSocialMedia_Profile on Profile {
    facebookUrl: facebook(format: URL)
    instagramUrl: instagram(format: URL)
    twitterUrl: twitter(format: URL)
    youtubeUrl: youtube(format: URL)
  }
`);

export default function BrandSocialMedia({
  brand: data,
}: {
  brand: FragmentType<typeof fragment> | null | undefined;
}) {
  const brand = useFragment(fragment, data);
  if (!brand) return null;
  // Filter out social media with __null
  const rebrand = {
    facebookUrl: brand?.facebookUrl?.includes("__null")
      ? null
      : brand.facebookUrl,
    instagramUrl: brand?.instagramUrl?.includes("__null")
      ? null
      : brand.instagramUrl,
    twitterUrl: brand?.twitterUrl?.includes("__null") ? null : brand.twitterUrl,
    youtubeUrl: brand?.youtubeUrl?.includes("__null") ? null : brand.youtubeUrl,
  };
  return (
    <AddressSegment>
      {rebrand?.facebookUrl && (
        <IconLink href={rebrand.facebookUrl} title="Facebook">
          <FacebookIcon size="medium" sx={{ color: "primary" }} />
        </IconLink>
      )}
      {rebrand.instagramUrl && (
        <IconLink href={rebrand.instagramUrl} title="Instagram">
          <InstagramIcon size="medium" sx={{ color: "primary" }} />
        </IconLink>
      )}
      {rebrand.twitterUrl && (
        <IconLink href={rebrand.twitterUrl} title="Twitter">
          <TwitterIcon size="medium" sx={{ color: "primary" }} />
        </IconLink>
      )}
      {rebrand.youtubeUrl && (
        <IconLink href={rebrand.youtubeUrl} title="YouTube">
          <YoutubeIcon size="medium" sx={{ color: "primary" }} />
        </IconLink>
      )}
    </AddressSegment>
  );
}

function IconLink(props: {
  href: string;
  title: string;
  children: React.ReactNode;
}) {
  // Returns a link with enough padding to be touched with a finger (at least ~40px).
  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <Link
      sx={{ display: "inline-block", marginRight: 1 }}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    />
  );
}

function AddressSegment({ children }) {
  if (!children) return null;
  return (
    <div sx={{ display: "flex", paddingBottom: 2 }}>
      <Text>{children}</Text>
    </div>
  );
}
